import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';

import { AppProvider } from './hooks';

import 'primeflex/primeflex.css';

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";

import 'primereact/resources/primereact.min.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import 'bootstrap/dist/css/bootstrap.min.css';
// teste

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <Routes />
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
