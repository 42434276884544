/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { UserAuth } from '../../hooks/authUser';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { BiUser } from 'react-icons/bi'

import { FcServices } from 'react-icons/fc'

import { Link } from 'react-router-dom';

import {Content,  Container } from './styles'; 

import Logo from '../../assets/logo.JPG'


import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { api } from '../../services/api';

import { Toast } from 'primereact/toast';

import { TabView, TabPanel } from 'primereact/tabview';

export function MenuDashboard() {
    const { signOut } = UserAuth();
    return (
        <Container>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand as={Link} to="/dashboard" className="logo">
                    <img src={Logo} atl="Logo" width="60px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/dashboard">Principal</Nav.Link>
                        <Nav.Link as={Link} to="/dashboard/atendimento">Atendimento</Nav.Link>
                        <Nav.Link as={Link} to="/campanha">Campanha</Nav.Link>
                        <Nav.Link as={Link} to="/atualizardados">Atualizar Dados</Nav.Link>
                        {/* <Nav.Link as={Link} to="/cadastros" >Cadastros</Nav.Link> */}
                        {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                    <Nav>
                        <div className="tarefas">
                            <NavDropdown title={<FcServices size={25} color="#f52" />} id="collasible-nav-dropdown" className="dropdown">
                                <NavDropdown.Divider />
                                <div >
                                   <h6>Painel de Tarefas</h6>
                                   <div className="p-grid">
                <Content>
                    <div>
                        <TabView >
                            <TabPanel header="WS Detran">
                                <div className="p-col-12">
                                    <div className="mt-3">Aqui você realiza a atualização dos dados do banco pegando<br />informações diretas do Web Service do DETRAN.</div>
                                    <div className="mt-4">Data de Início e Fim da consulta</div>
                                    <div>
                                        <Button className="p-button-success mt-3" label="Atualizar Dados"  />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="ERP Otimiza">
                                <div className="p-col-12">
                                    <div className="mt-3">Aqui você realiza a atualização dos dados do banco pegando<br />informações diretas do ERP Otimiza.</div>
                                    <div className="mt-4">Data de Início e Fim da consulta</div>
                                    <div>
                                        <Button className="p-button-success mt-3" label="Atualizar Dados" />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Importar dados via Planilha CSV">
                                <div className="p-col-12">
                                    <div className="mt-3">Você também pode importar dados realizando upload por uma planilha CSV.</div>
                                    <FileUpload name="upload[]"
                                        className="mt-4"
                                        url=""
                                        multiple
                                        maxFileSize={10000000}
                                        emptyTemplate={<p className="p-m-0">Arraste e solte arquivos aqui para realizar o upload.</p>}
                                        accept={".csv"}
                                    />
                                </div>
                            </TabPanel>
                        </TabView>

                    </div>
                </Content>
            </div>

                                </div>
                            </NavDropdown>
                        </div>
                    </Nav>

                    <Nav>
                        <div className="login">
                            <NavDropdown title={<BiUser size={25} color="#fff" />} id="collasible-nav-dropdown" className="dropdown">
                                {/* <NavDropdown.Item href="#action/3.1">Perfil</NavDropdown.Item> */}
                                {/* <NavDropdown.Divider /> */}
                                <NavDropdown.Item onClick={() => signOut()}>Sair</NavDropdown.Item>
                            </NavDropdown>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </Container>
    )
}