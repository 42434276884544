import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Form } from '@unform/web';

import Modal from "react-bootstrap/Modal";

import { IoIosClose } from "react-icons/io"

import Input from '../../../components/Input';
import { Radio } from '../../../components/InputRadio';
import InputMask from '../../../components/InputMask';
import { Toast } from 'primereact/toast';

import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";

import { Container } from './styles';
import { api } from '../../../services/api';

export function UpdateVeiculo({ editVeiculo, handleUpdateVeiculo, ...props }) {
    const formRef = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        async function getAgregador() {
            if (formRef.current !== null) {
                if (editVeiculo.cpfcnpjagregador !== null) {
                    const response = await api.get(`/agregador?cpfcnpjagregador=${editVeiculo.cpfcnpjagregador}`)
                    formRef.current.setFieldValue('nome_agregador', response.data.nome)

                    const response2 = await api.get(`/agregador?cpfcnpjagregador=${editVeiculo.cnpjfinanceiro}`)
                    formRef.current.setFieldValue('nome_financeiro', response2.data.nome)
                }
            }
        }

        getAgregador()
    }, [editVeiculo])

    async function handleOnBlur() {
        try {
            const getInput = formRef.current.getFieldValue("cpfcnpjagregador")
            const response = await api.get(`/agregador?cpfcnpjagregador=${getInput}`)
            formRef.current.setFieldValue('cpfcnpjagregador', getInput)
            formRef.current.setFieldValue('nome_agregador', response.data.nome)
        } catch (err) {
            formRef.current.setFieldValue("cpfcnpjagregador", '');
            formRef.current.setFieldValue('nome_agregador', '')
            toast.current.show({
                life: 10000,
                severity: 'error',
                summary: 'Mensagem de Erro',
                detail: 'Proprietario Agregador não encontrado, favor adiciona-lo em "Incluir Proprietario"!'
            });
        }
    }

    async function handleOnBlurFinanceiro() {
        try {
            const getInputFinanceiro = formRef.current.getFieldValue("cnpjfinanceiro")
            const response = await api.get(`/agregador?cpfcnpjagregador=${getInputFinanceiro}`)
            formRef.current.setFieldValue('cnpjfinanceiro', getInputFinanceiro)
            formRef.current.setFieldValue('nome_financeiro', response.data.nome)
        } catch (err) {
            formRef.current.setFieldValue("cnpjfinanceiro", '');
            formRef.current.setFieldValue('nome_financeiro', '')
            toast.current.show({
                life: 10000,
                severity: 'error',
                summary: 'Mensagem de Erro',
                detail: 'Proprietario Agregador não encontrado, favor adiciona-lo em "Incluir Proprietario"!'
            });
        }
    }

    const handleSubmit = useCallback(async (data) => {
        try {
            const schema = Yup.object().shape({
                placa: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            const newData = {
                cappassageiros: Number(data.cappassageiros),
                anomodelo: data.anomodelo,
                carroceria: data.carroceria,
                chassi: data.chassi,
                combustivel: data.combustivel,
                cor: data.cor,
                especietipo: data.especietipo,
                marcamodeloversao: data.marcamodeloversao,
                cilindrada: Number(data.cilindrada),
                cpfcnpjagregador: data.cpfcnpjagregador,
                cnpjfinanceiro: data.cnpjfinanceiro,
                flag7icar: data.flag7icar,
                numerocompartimentostanque: Number(data.numerocompartimentostanque),
                cmt: Number(data.cmt),
                pbt: Number(data.pbt),
                tara: Number(data.tara),
                potenciacilindrada: Number(data.potenciacilindrada),
                placa: data.placa,
                renavam: data.renavam,
                placamercosul: data.placamercosul,
                tipoprodutotransporte: data.tipoprodutotransporte,
            }
            await handleUpdateVeiculo(newData);
            props.onHide();
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
            toast.current.show({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Não foi possivel atualizar veiculo!' });
        }
    }, [handleUpdateVeiculo, props]);

    function handleClose() {
        props.onHide();
    }

    return (
        <>
            <Toast ref={toast} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Container>
                    <Modal.Body>
                        <div className="mb-4" style={{ display: 'flex', alignItems: 'center' }}>
                            <h2>Editar Veiculo</h2>
                            <IoIosClose size={34} color="red" onClick={handleClose} />
                        </div>
                        <FormBootstrap
                            as={Form}
                            ref={formRef}
                            onSubmit={handleSubmit}
                            initialData={editVeiculo}
                        >
                            <Row>
                                <Col style={{ maxWidth: 120 }}>
                                    <FormBootstrap.Label>Placa</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="placa"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Placa Mercosul</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="placamercosul"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Ano Modelo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="anomodelo"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Cap.Passageiros</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cappassageiros"
                                        type="number"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 300 }}>
                                    <FormBootstrap.Label>Carroceria</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="carroceria"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Cilindrada</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cilindrada"
                                        type="number"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ width: 400 }}>
                                    <FormBootstrap.Label>Chassi</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="chassi"
                                    />
                                </Col>

                                <Col style={{ maxWidth: 300 }}>
                                    <FormBootstrap.Label>combustivel</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="combustivel"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Cor</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cor"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Especie-tipo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="especietipo"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Marca Modelo Versão</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="marcamodeloversao"
                                        style={{ maxWidth: 250 }}
                                    />
                                </Col>
                                <Col style={{ maxWidth: 120 }}>
                                    <FormBootstrap.Label>CMT</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cmt"
                                        type="number"
                                    />
                                </Col>

                                <Col style={{ maxWidth: 100 }}>
                                    <FormBootstrap.Label>PBT</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="pbt"
                                        type="number"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 100 }}>
                                    <FormBootstrap.Label>Tara</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="tara"
                                        type="number"
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col style={{ maxWidth: 250 }}>
                                    <FormBootstrap.Label>Potencia Cilindrada</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="potenciacilindrada"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Renavam</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="renavam"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Agente Financeiro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cnpjfinanceiro"
                                        onBlur={handleOnBlurFinanceiro}
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Agregador CNPJ</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={InputMask}
                                        mask="99.999.999/9999-99"
                                        maskChar=""
                                        name="cpfcnpjagregador"
                                        onBlur={handleOnBlur}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Nome do Financeiro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="nome_financeiro"
                                        disabled
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Nome do Agregador</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        type="text"
                                        name="nome_agregador"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <fieldset style={{ border: '1px solid #e63946', padding: 20, marginTop: 10 }}>
                                <legend style={{ color: '#d00000', fontFamyly: 'Roboto', fontWeight: 600, textAlign: 'center' }}>Produtos Perigosos</legend>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Group as={Row} style={{ alignItems: 'center', gap: '10px' }}>
                                            <FormBootstrap.Label as="legend" column sm={2}>
                                                Flag
                                        </FormBootstrap.Label>
                                            <Radio
                                                name="flag7icar"
                                                options={[
                                                    { id: 0, label: "7i" },
                                                    { id: 1, label: "car" },
                                                    { id: undefined, label: "Nenhum" },
                                                ]}
                                            />
                                        </FormBootstrap.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col >
                                        <FormBootstrap.Label>Número Compartimentos do tanque</FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            as={Input}
                                            name="numerocompartimentostanque"
                                            type="number"
                                            style={{ maxWidth: 200 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>Tipo Produto Transporte</FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            as={Input}
                                            name="tipoprodutotransporte"
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <Button type="submit" className="mt-2 mb-4">Atualizar</Button>
                            <Button disabled className="ml-2 mt-2 mb-4">Atualizar dados automaticamente</Button>
                        </FormBootstrap>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => props.onHide()}>Fechar</Button>
                    </Modal.Footer>
                </Container>
            </Modal>
        </>
    )
}