import React, { createContext, useCallback, useContext, useState } from 'react';

import { api } from '../services/api';

const UserAuthContext = createContext({});

const UserAuthProvider = ({ children }) => {

    const [data, setData] = useState(() => {
        const token = localStorage.getItem('@CRMITL:token');
        const user = localStorage.getItem('@CRMITL:user');

        if (token && user) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return { token, user: JSON.parse(user) };
        }

        return {};
    });

    const signIn = useCallback(async ({ email, senha }) => {

        const response = await api.post(`/login`, {
            email,
            senha,
        });

        const { token, usuario } = response.data;

        localStorage.setItem('@CRMITL:token', token);
        localStorage.setItem('@CRMITL:user', JSON.stringify(usuario));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user: usuario });
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@CRMITL:token');
        localStorage.removeItem('@CRMITL:user');

        setData({});
    }, []);

    const updateUser = useCallback((user) => {
        localStorage.setItem('@CRMITL:user', JSON.stringify(user));

        setData({
            token: data.token,
            user,
        })
    }, [data.token])

    return (
        <UserAuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser }}>
            {children}
        </UserAuthContext.Provider>
    );
};

function UserAuth() {
    const context = useContext(UserAuthContext);

    if (!context) {
        throw new Error('userAuth must be used within an UserAuthProvider');
    }

    return context;
}

export { UserAuthProvider, UserAuth }