import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'

import { Container } from "./styles";

export default function Input({ name, ...rest }) {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFilled, SetIsFilled] = useState(false);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    const handleInputFocus = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);

        SetIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: ref => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: ref => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
            isFocused={isFocused}
        >
            <input
                ref={inputRef}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                {...rest}
            />
            {error && (
                <div className="erro">
                    <span className="error">*{error}</span>
                </div>
            )}
        </Container>
    )
}