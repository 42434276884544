import styled from 'styled-components';

export const Container = styled.div`
    h1 {
        margin: 20px 0 0 32px;
    }

    #phonePriority {
        transition: 0.2s;
       &:hover {
           text-decoration: underline;
           color: #2a9d8f;
           cursor: pointer;
       }
    }

    .p-column-title.media {
        display: none;
    }

    @media (max-width: 600px) {
        .datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
            display: none;
        }
    }

    @media screen and (max-width: 40em) {
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
            display: none !important;
        }

        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
            text-align: left;
            display: block;
            width: 100%;
            float: left;
            clear: left;
            border: 0 none;
        }

        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4em 1em -.4em -.4rem;
            font-weight: bold;
        }

        .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
            border-bottom: 1px solid var(--surface-d);
        }
    }

`;

export const DataTableVeiculosContatos = styled.div`
    display: flex;
    gap: 10px;

    /* div.p-datatable.p-component.p-datatable-responsive-demo div.p-datatable-wrapper table tbody tr td.columnVeiculo {
        border: 1px solid red;
    } */

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
    }
`

export const TopSearch = styled.div`
    margin: 20px 32px 32px 32px;
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    max-width: 900px;
    align-items: center;
    justify-content: space-between;
    
    .agenda {
        width: 200px;
        margin-left: 100px;
        border: none;
        border-radius: 5px;
        text-transform: uppercase;
        background: #1d3557;
        color: #fff;
    }


    .content {
        display: grid;
        grid-template-columns: 1fr 0.3fr;

        input {
            border: none;
            height: 40px;
            background: #e5e5e5;
            padding: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        .btn-not-houver {
            &:hover {
                box-shadow: none;
            }
        }

        button {
            text-transform: uppercase;
            border: none;
            height: 40px;
            background: #55a630;
            color: #fff;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;

            transition: 0.2s;

            &:hover {
                box-shadow: 0.5px 0.2px 3px 0.5px rgba(0, 0, 0, 0.5);
            }
            
            &:active {
                box-shadow: 0.5px 0.2px 3px 0.5px rgba(0, 0, 0, 0.1);
            }
        }

        .createProprietario {
            margin-right: 20px;
            border: 1px solid red;
        }

    }

    /* .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e5e5e5;
        box-shadow: 1px 1px 2px 0.1px rgba(0, 0, 0, 0.5);
    } */

    @media (max-width: 700px) {
        grid-template-columns: 1fr;

        .addProprietario {
            margin-top: 32px;
        }
    }
`;

export const Content = styled.div`
    display: grid;
    /* grid-template-columns: 1fr 0.3fr; */
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 0 32px;

    @media (max-width: 980px) {
        grid-template-columns: 1fr;
    }
    .tableMain {
        margin-bottom: 10px;

        svg {
            transition: filter 0.2s;
            &:hover {
                cursor: pointer;
                filter: brightness(0.9);
            }
        }

        .p-datatable-header {
            text-align: center;
        }

        .p-datatable .p-datatable-tbody > tr {
            &:hover {
                /* filter: brightness(0.9); */
                /* cursor: pointer; */

                &:active {
                    /* background-color: red; */
                }
            }
        }

        .icon1 {
            position: relative;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            /* background: #000; */

            svg {
                position: absolute;
                right: 10px;
                top: 15px;
                z-index: 1;
                transition: filter 0.2s;
            }

            .twoIcons {
                svg:first-child {
                    right: 50px;
                }
            }
        }

        .tableRow {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .ajust {
                margin: 10px 5px 0 0;
            }

            .ajust2 {
                margin: 10px 0 0 5px;
            }

            @media (max-width: 1120px) {
                grid-template-columns: 1fr;

                .ajust {
                    margin: 10px 0 0 0;
                }

                .ajust2 {
                    margin: 10px 0 0 0;
                }
            }
        }
    }

    .buttonsSearch {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
    }
`;

export const RadioButton = styled.button`
    background: ${(props) => props.isActive};
    color: ${(props) => props.isActive ? '#fff' : '#000'};

    height: 32px;
    margin: 0 5px;
    padding: 5px 10px;
    /* width: 120px; */

    border: none;
    border-radius: 5px;
`;