import styled, {css} from "styled-components";

export const Container = styled.div`
    input {
        /* border: 1px solid red; */
        ${(props) =>
        props.isErrored &&
        css`
            border: 1px solid #c53030;
        `}

        ${props =>
        props.isFilled &&
        css`
        color: #55a630;
        border-color: #55a630;
        `}
    }

    span {
        /* position: absolute; */
        text-align: right;
        font-family: 'Roboto';
        font-size: 0.8rem;
        color: #c53030;
        margin: 10px;
    }
`;