import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
// import { IconBaseProps } from 'react-icons';
// import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import { Container } from './styles';


const Input = ({ name, containerStyle = {}, icon: Icon, ...rest }) => {
    const inputRef = useRef(null);
    const [isFilled, SetIsFilled] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    const handleInputBlur = useCallback(() => {
        SetIsFilled(!!inputRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container
            style={containerStyle}
            isErrored={!!error}
            isFilled={isFilled}
        >
            {/* {Icon && <Icon size={20} />} */}
            {/* <label>{rest.label}</label> */}
            <InputMask
                onBlur={handleInputBlur}
                defaultValue={defaultValue}
                ref={inputRef}
                {...rest}
            />
            {error && (
                <div className="erro">
                    <span className="error">*{error}</span>
                </div>
            )}
        </Container>
    );
};

export default Input;
