import styled from 'styled-components';


export const Content = styled.div`
    margin: 32px 32px;

    section + section {
        margin-top: 20px;
    }

    .dataTable1 {

        .searchButtons {
            margin-bottom: 10px;

            button {
                transition: filter 0.2s;
            }
            
            button:hover {
                filter: brightness(0.9);
            }

            button + button {
                margin-left: 5px;
            }
        }
    }

    .icon1 {
            position: relative;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            /* background: #000; */

            svg {
                position: absolute;
                right: 10px;
                top: 15px;
                z-index: 10;
                transition: filter 0.2s;

                &:hover {
                    cursor: pointer;
                    filter: brightness(0.6);
                }
            }
        }
`;

export const Container = styled.div`
    /* position: absolute; */
    width: 100%;
    top: 0;
    .dropdown-menu.show {
        /* width: max(100%, 200px); */
        width: 100%;
        padding: 10px 10px;

        /* @media (max-width: 950px) {
            width: 100%
        } */
    }

    .login {
        .dropdown-menu.show{
            left: -125px;
            right: 0;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .nav-link {
            display: inline;
        }
    }

    .tarefas {
        .dropdown-menu.show{
            left: -225px;
            right: 0;
        }

        .dropdown-toggle::after {
            display: none;
        }

        .nav-link {
            display: inline;
        }

        margin-right: 100px;
        width: 100%;
    }

    
`;