import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    .content {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        form {
            border: 1px solid #ccc;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
            padding: 32px;
            width: 25%;

            h2 {
                font-family: Roboto;
                font-weight: 400;
                text-align: center;
                font-size: 1.8rem;
            }

            @media (max-width: 900px) {
                width: 300px;
                margin: 0 10px;
            }

            input {
                width: 100%;
            }

            .buttons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: auto 0;

                label {
                    height: 100%;
                    margin: auto 0;
                }
            }
        }

        .groupLabel {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`;