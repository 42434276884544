import React, { useState, useRef } from 'react';

import { Button, Spinner } from "react-bootstrap";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';

import { api } from '../../services/api';
import { MdModeEdit } from 'react-icons/md';
import { FaTruck } from 'react-icons/fa';
import { BsGearFill } from 'react-icons/bs';

import { MenuDashboard } from '../../components/MenuDashboard';
import Input from '../../components/Input';
import { Form } from '@unform/web';



import {
    Container,
    DataTableVeiculosContatos,
    TopSearch,
    Content
} from './styles';

import { UpdateProprietario } from './UpdateProprietario';
import { AddProprietario } from './AddProprietario';
import { UpdateVeiculo } from './UpdateVeiculo';
import { AddVeiculo } from './AddVeiculo';
import { AddContato } from './AddContato';
import { UpdateContato } from './UpdateContato';
import { AddLaudo } from './AddLaudo';
import { UpdateLaudo } from './UpdateLaudo';
import { ModalEscopos } from './modalEscopo';



export function BuscaProprietario() {
    
    const formRef = useRef(null);
    const toast = useRef(null);
    const [spinner, setSpinner] = useState(false);
    const [dadosSearch, setDadosSearch] = useState([]);
    const [idProprietario, setIdProprietario] = useState();
    const [idVeiculo, setIdVeiculo] = useState();

    const [modalAddProprietario, setModalAddProprietario] = useState(false);
    const [modalUpdateProprietario, setModalUpdateProprietario] = useState(false);
    const [editProprietario, setEditProprietario] = useState('');

    const [modalAddVeiculo, setModalAddVeiculo] = useState(false);
    const [modalUpdateVeiculo, setModalUpdateVeiculo] = useState(false);
    const [editVeiculo, setEditVeiculo] = useState('');

    const [modalAddContato, setModalAddContato] = useState(false);
    const [modalUpdateContato, setModalUpdateContato] = useState(false);
    const [editContato, setEditContato] = useState('');

    const [modalAddLaudo, setModalAddLaudo] = useState(false);
    const [modalUpdateLaudo, setModalUpdateLaudo] = useState(false);
    const [editLaudo, setEditLaudo] = useState('');

    const [modalEscopos, setModalEscopos] = useState(false);

    const [expandedRowsVeiculoContato, setExpandedRowsVeiculoContato] = useState([]);
    const [expandedRowsLaudos, setExpandedRowsLaudos] = useState([]);
    const [expandedRowsContatos, setExpandedRowsContatos] = useState([]);

    function handleEditProprietario(dataProp) {
        setEditProprietario(dataProp)
        setModalUpdateProprietario(true)
    }

    function handleEditVeiculo(dataVeiculo) {
        setEditVeiculo(dataVeiculo)
        setModalUpdateVeiculo(true)
    }

    function handleEditLaudo(dataLaudo) {
        setEditLaudo(dataLaudo)
        setModalUpdateLaudo(true)
    }

    function handleEditContato(dataContato) {
        setEditContato(dataContato)
        setModalUpdateContato(true)
    }

    const handleSubmitSearch = async (data) => {
        try {
            setSpinner(true);

            const newData = data.filtro.replace(/\.|-|\/|\\|\(|\)/g, '')
            const filtro = newData
            // const filtro = {
            //     filtro: newData
            // }
            const response = await api.get(`/proprietario/dados?filtro=${filtro}`);
            // const response = await api.post('/veiculo/consulta', filtro);

            const newDataSearch = response.data.map(oldValue => {
                return {
                    ...oldValue,
                    telefones_proprietario: oldValue.telefones_proprietario ? oldValue.telefones_proprietario : [],
                    veiculos: oldValue.veiculos ? oldValue.veiculos : []
                }
            })
            setDadosSearch(newDataSearch)
        } catch (err) {
            setDadosSearch([])
                               
            toast.current.show({ severity: 'warn', summary: 'Mensagem de aviso!', detail: 'Não houve resultados para essa consulta, tente novamente!' });

        } finally {
            setSpinner(false);
        }
    }

    const handleAddContato = async (dadosAddContato) => {
        toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Contato criado com sucesso!' });

        setDadosSearch((prevState) =>
            prevState.map((item) => {
                if (item.id_proprietario === idProprietario) {
                    return {
                        ...item,
                        contatos: [...item.contatos, dadosAddContato]
                    };
                }
                return item;
            })
        )
    }

    const handleAddProprietario = async (dadosAddProp) => {
        setDadosSearch([dadosAddProp]);
    }

    const handleUpdateProprietario = async (dadosProp) => {
        const response = await api.put(`/proprietario/${editProprietario.id_proprietario}`, {
            ...editProprietario,
            ...dadosProp,
        });

        toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Proprietario atualizado com sucesso!' });

        setDadosSearch((prevState) =>
            prevState.map((mappedDados) =>
                mappedDados.id_proprietario === editProprietario.id_proprietario
                    ? { ...mappedDados, ...response.data }
                    : mappedDados
            )
        );
    }

    function handleStateAddTelefoneProprietario(dataTelefone) {
        setDadosSearch(draft =>
            draft.map(itemProprietario => {
                if (itemProprietario.id_proprietario === dataTelefone.proprietario_id) {
                    return {
                        ...itemProprietario,
                        telefones_proprietario: [...itemProprietario.telefones_proprietario, dataTelefone]
                    }
                }
                return itemProprietario
            })

        )
    }

    function stateUpdateTelefoneProprietario(response) {
        setDadosSearch(draft =>
            draft.map(item => {
                if (item.id_proprietario === response.proprietario_id) {
                    return {
                        ...item,
                        telefones_proprietario: item.telefones_proprietario.map(mapped =>
                            mapped.id_telefone === response.id_telefone ? { ...mapped, ...response } : mapped
                        )
                    }
                }
                return item
            })
        )
    }

    function updateTelefonePrioritarioProprietario(arrayDataTelefone, dataProprietario) {
        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                if (mappedDados.id_proprietario === dataProprietario.id_proprietario) {
                    return {
                        ...mappedDados,
                        telefones_proprietario: arrayDataTelefone
                    }
                }

                return mappedDados
            })
        );
    }

    function handleDeleteTelefoneProprietario(dataTelefone) {
        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                if (mappedDados.id_proprietario === dataTelefone.proprietario_id) {
                    return {
                        ...mappedDados,
                        telefones_proprietario: mappedDados.telefones_proprietario.filter(item =>
                            item.id_telefone !== dataTelefone.id_telefone
                        )
                    }
                }

                return mappedDados
            })
        );
    }

    const handleAddVeiculo = async (dadosAddVeiculo, idProprietario) => {
        const response = await api.post('/veiculo', {
            ...dadosAddVeiculo,
            proprietario_id: idProprietario
        })

        if (response.data.created === false) {
            return toast.current.show({ severity: 'warn', summary: 'Mensagem de aviso', detail: 'A placa do veiculo já existe, tente novamente!' });
        }

        const newData = {
            ...response.data.veiculo,
            laudos: []
        }

        toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Veiculo criado com sucesso!' });

        setDadosSearch((prevState) =>
            prevState.map((item) => {
                if (item.id_proprietario === idProprietario) {
                    return {
                        ...item,
                        veiculos: [...item.veiculos, newData]
                    };
                }
                return item;
            })
        )
    }

    const handleUpdateVeiculo = async (dadosVeiculo) => {
        const response = await api.put(`/veiculo/${editVeiculo.id_veiculo}`, {
            ...editVeiculo,
            ...dadosVeiculo
        });

        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                return {
                    ...mappedDados,
                    veiculos: mappedDados.veiculos.map(mappedV =>
                        mappedV.id_veiculo === editVeiculo.id_veiculo ? { ...mappedV, ...response.data } : mappedV
                    )
                }
            })
        );

        toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Veiculo atualizado com sucesso!' });
    }

    const handleUpdateContato = async (dadosContato) => {
        const response = await api.put(`/contato/${editContato.id_contato}`, {
            ...editContato,
            ...dadosContato
        });
        toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Contato atualizado com Sucesso' });
        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                return {
                    ...mappedDados,
                    contatos: mappedDados.contatos.map(mappedV =>
                        mappedV.id_contato === editContato.id_contato ? { ...mappedV, ...response.data } : mappedV
                    )
                }
            })
        );
    }

    const agregadorBodyTemplate = (rowData) => {
        return (
            <>
                <BsGearFill title="Escopos" className="mr-2" onClick={() => setModalEscopos(true)} />
                {rowData.cpfcnpjagregador !== null && (
                    <FaTruck style={{ cursor: 'not-allowed' }} size={20} />
                )}
            </>
        )
    }

    function handleModalAddVeiculo(data) {
        setIdProprietario(data.id_proprietario)
        setModalAddVeiculo(true)
    }

    function handleModalAddContato(data) {
        setIdProprietario(data.id_proprietario)
        setModalAddContato(true)
    }

    // Tabela Veiculos ************************************************************************
    const placaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Placa</span>
                {rowData.placa}
            </React.Fragment>
        )
    }

    const combustivelBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Combustivel</span>
                {rowData.combustivel}
            </React.Fragment>
        )
    }

    const anoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Ano</span>
                {rowData.anomodelo}
            </React.Fragment>
        )
    }


    // Tabela Contatos ***********************************************************************
    const nomeContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Nome</span>
                {rowData.nome}
            </React.Fragment>
        )
    }

    const cargoContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Cargo</span>
                {rowData.cargo}
            </React.Fragment>
        )
    }

    const getNumberPriority = (rowData) => {
        let filterPriority = ''
        if (rowData.telefones_contato !== undefined) {
            [filterPriority] = rowData.telefones_contato.filter(old => old.prioritario === 1);
        }

        const formatDDD = filterPriority ? filterPriority.ddd.replace(/(\d{1})(\d{1})/, "($1$2)") : ''
        const formatNumberPhone = filterPriority ? filterPriority.numerotel.replace(/(\d{5})(\d{4})/, "$1-$2") : ''
        return (
            <React.Fragment>
                {filterPriority === undefined ? (
                    <span id="phonePriority" className="p-column-title" onClick={() => handleEditContato(rowData)}>Defina um telefone prioritario</span>
                ) : (
                    <>
                        <span className="p-column-title media">Telefone Prioritario</span>
                        {formatDDD}{formatNumberPhone}
                    </>
                )}
            </React.Fragment>
        )
    }

    const rowExpansionTemplate = (data) => {
        return (
            <DataTableVeiculosContatos>
                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            header={<Button onClick={() => handleModalAddVeiculo(data)}>Adicionar Veiculo</Button>}
                            value={data.veiculos}
                            expandedRows={expandedRowsLaudos}
                            onRowToggle={(e) => setExpandedRowsLaudos(e.data)}
                            rowExpansionTemplate={rowExpansionLaudo}
                            className="p-datatable-responsive-demo"
                            dataKey="id_veiculo"
                        >
                            <Column expander style={{ width: '3em' }} />
                            <Column field="placa" header="Placa" body={placaBodyTemplate} />
                            <Column field="combustivel" header="Combustivel" body={combustivelBodyTemplate} />
                            <Column field="anomodelo" header="Ano" body={anoBodyTemplate} />
                            <Column style={{ width: 150 }} field="agregador" header="Agregador" body={agregadorBodyTemplate} />
                            <Column
                                field="action"
                                header="Ações"
                                alt="asd"
                                body={actionVeiculoBodyTemplate}
                                style={{ width: '80px' }} />
                        </DataTable>
                    </div>
                </div>

                <div className="datatable-responsive-demo">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={5}
                            value={data.contatos}
                            header={<Button onClick={() => handleModalAddContato(data)}>Adicionar Contato</Button>}
                            expandedRows={expandedRowsContatos}
                            onRowToggle={(e) => setExpandedRowsContatos(e.data)}
                            rowExpansionTemplate={rowExpansionLaudo}
                            dataKey="id_contato"
                            className="p-datatable-responsive-demo"
                        >
                            <Column field="nome" header="Nome" body={nomeContatoBodyTemplate} />
                            <Column field="numero" header="Telefone Prioritario" body={getNumberPriority} />
                            <Column field="cargo" header="Cargo" body={cargoContatoBodyTemplate} />
                            <Column
                                field="action"
                                header="Ações"
                                alt="asd"
                                body={actionContatoBodyTemplate}
                                className="columnVeiculo"
                                style={{ width: '80px' }}
                            />
                        </DataTable>
                    </div>
                </div>
            </DataTableVeiculosContatos>
        );
    }

    function getIdVeiculo(id_veiculo) {
        setIdVeiculo(id_veiculo)
        setModalAddLaudo(true)
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        const dataEmissãoCSV = new Date(rowData.data_emissaocsv).toLocaleDateString()
        return (
            <React.Fragment>
                {rowData.data_emissaocsv !== null && (
                    <span>{dataEmissãoCSV}</span>
                )}
            </React.Fragment>
        )
    }

    const dataVencimentoBodyTemplate = (rowData) => {
        const dataVencimentocsv = new Date(rowData.data_vencimentocsv).toLocaleDateString()
        return (
            <React.Fragment>
                {rowData.data_vencimentocsv !== null && (
                    <span>{dataVencimentocsv}</span>
                )}
            </React.Fragment>
        )
    }

    const rowExpansionLaudo = (data) => {
        const id_veiculo = data.id_veiculo
        return (
            <div>
                <div className="card">
                    <>
                        <DataTable
                            paginator
                            rows={5}
                            // header={<Button onClick={() => setModalAddLaudo(true)}>Adicionar Laudo</Button>}
                            header={
                                <>
                                    <Button className="mr-2" onClick={() => getIdVeiculo(id_veiculo)}>Adicionar Laudo</Button>
                                    <Button disabled onClick={() => { }}>Atualizar laudos</Button>
                                </>
                            }
                            value={data.laudos}
                            dataKey="id_laudo"
                        >
                            <Column field="data_emissaocsv" header="Data Emissao" body={dataEmissaoBodyTemplate} sortable />
                            <Column field="escopo_id" header="Escopo" />
                            <Column field="data_vencimentocsv" header="Vencimento" body={dataVencimentoBodyTemplate} sortable />
                            <Column
                                field="action"
                                header="Ações"
                                alt="asd"
                                body={actionLaudoBodyTemplate}
                                style={{ width: '80px' }} />
                        </DataTable>
                    </>
                </div>
            </div>
        );
    }

    async function handleAddLaudo(addDataLaudo, idVeiculoLaudo) {
        const response = await api.post(`${idVeiculoLaudo}/laudo`, addDataLaudo)
        if (response.data[1] === true) {
            setDadosSearch((prevState) =>
                prevState.map((mappedDados) => {
                    return {
                        ...mappedDados,
                        veiculos: mappedDados.veiculos.map((mappedV) => {
                            if (mappedV.id_veiculo === idVeiculoLaudo) {
                                return {
                                    ...mappedV,
                                    laudos: [...mappedV.laudos, response.data[0]]
                                };
                            }

                            return mappedV;
                        })
                    };
                })
            );
            toast.current.show({ severity: 'success', summary: 'Mensagem de sucesso', detail: 'Laudo criado com sucesso!' });
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Número laudo repetido, tente novamente!' });
        }
    }

    async function handleUpdateLaudo(updateDataLaudo, dataId) {
        await api.patch(`/laudo/${dataId.id_laudo}`, updateDataLaudo)

        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                return {
                    ...mappedDados,
                    veiculos: mappedDados.veiculos.map((mappedV) => {
                        if (mappedV.id_veiculo === dataId.veiculo_id) {
                            return {
                                ...mappedV,
                                laudos: mappedV.laudos.map((itemLaudo) =>
                                    itemLaudo.id_laudo === dataId.id_laudo
                                        ? { ...itemLaudo, ...updateDataLaudo }
                                        : itemLaudo
                                )
                            };
                        }

                        return mappedV;
                    })
                };
            })
        );
    }

    const actionProprietarioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Ações</span>
                <MdModeEdit size={20} onClick={() => handleEditProprietario(rowData)} />
            </React.Fragment>
        );
    }

    const actionLaudoBodyTemplate = (rowData) => {
        const newRowData = {
            ...rowData,
            data_emissaocsv: rowData.data_emissaocsv === null ? '' : new Date(rowData.data_emissaocsv),
            data_relacionamento: rowData.data_relacionamento === null ? '' : new Date(rowData.data_relacionamento),
            data_vencimentocsv: rowData.data_vencimentocsv === null ? '' : new Date(rowData.data_vencimentocsv),
        }
        return (
            <React.Fragment>
                <MdModeEdit size={20} onClick={() => handleEditLaudo(newRowData)} />
            </React.Fragment>
        );
    }

    const actionVeiculoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <MdModeEdit size={20} onClick={() => handleEditVeiculo(rowData)} />
            </React.Fragment>
        );
    }

    const actionContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <MdModeEdit size={20} onClick={() => handleEditContato(rowData)} />
            </React.Fragment>
        );
    }

    const ProprietarioBodyNome = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">Nome</span>
                {rowData.nome}
            </React.Fragment>
        )
    }
    const ProprietarioBodyCPFCNPJ = (rowData) => {
        const formatValue = rowData.cpfcnpj.length === 11
            ? rowData.cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
            : rowData.cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        return (
            <React.Fragment>
                <span className="p-column-title media">CPF/CNPJ</span>
                {formatValue}
            </React.Fragment>
        )
    }

    function TelefonePrioritario(dadosTelefones) {
        setDadosSearch((prevState) => {
            const proprietario = prevState.find(
                (item) => item.id_proprietario === dadosTelefones.proprietario_id
            );

            if (!proprietario) return prevState;

            const contato = proprietario.contatos.find(
                (item) => item.id_contato === dadosTelefones.contato_id
            );

            if (!contato) return prevState;

            const telefone = contato.telefones_contato.find(
                (item) => item.numerotel === dadosTelefones.numerotel && item.ddd === dadosTelefones.ddd
            );

            if (telefone) {
                return prevState;
            }

            contato.telefones_contato.push(dadosTelefones);

            const newState = [...prevState];
            return newState;
        });
    }

    function stateUpdateTelefone(dadosUpdateTelefone) {
        setDadosSearch((prevState) => {
            const p = prevState.find(
                (item) => item.id_proprietario === dadosUpdateTelefone.proprietario_id
            );

            const c = p.contatos.find(
                (item) => item.id_contato === dadosUpdateTelefone.contato_id
            );

            const t = c.telefones_contato.find(
                (item) => item.id_telefone === dadosUpdateTelefone.id_telefone
            );

            t.ddd = dadosUpdateTelefone.ddd;
            t.numerotel = dadosUpdateTelefone.numerotel;
            t.tipo_telefone = dadosUpdateTelefone.tipo_telefone;

            const newState = [...prevState];
            return newState;
        });
    }

    function updateTelefonePrioritario(dataTelefone, dataContato) {
        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                return {
                    ...mappedDados,
                    contatos: mappedDados.contatos.map((mappedV) => {
                        if (mappedV.id_contato === dataContato.id_contato) {
                            return {
                                ...mappedV,
                                telefones_contato: dataTelefone
                            };
                        }

                        return mappedV;
                    })
                };
            })
        );
    }

    function handleDeleteTelefone(dataContato, dataTelefone) {
        setDadosSearch((prevState) =>
            prevState.map((mappedDados) => {
                return {
                    ...mappedDados,
                    contatos: mappedDados.contatos.map((mappedV) => {
                        if (mappedV.id_contato === dataContato.id_contato) {
                            return {
                                ...mappedV,
                                telefones_contato: mappedV.telefones_contato.filter((itemTel) =>
                                    itemTel.id_telefone !== dataTelefone.id_telefone
                                )
                            };
                        }

                        return mappedV;
                    })
                };
            })
        );
    }

    const proprietarioBodyTelefone = (rowData) => {
        let [filterPriority] = ''
        if (rowData.telefones_proprietario !== undefined) {
            [filterPriority] = rowData.telefones_proprietario.filter(old => old.prioritario === 1);
        }

        const formatDDD = filterPriority ? filterPriority.ddd.replace(/(\d{1})(\d{1})/, "($1$2)") : ''
        const formatNumberPhone = filterPriority ? filterPriority.numerotel.replace(/(\d{5})(\d{4})/, "$1-$2") : ''
        return (
            <React.Fragment>
                {filterPriority === undefined ? (
                    <span id="phonePriority" className="p-column-title" onClick={() => handleEditProprietario(rowData)}>Defina um telefone prioritario</span>
                ) : (
                    <>
                        <span className="p-column-title media">Telefone Prioritario</span>
                        {formatDDD}{formatNumberPhone}
                    </>
                )}
            </React.Fragment>
        )
    }

    const proprietarioBodyEmail = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title media">E-mail</span>
                {rowData.email}
            </React.Fragment>
        );
    }

    return (
        <Container>
            <MenuDashboard />

            <Toast ref={toast} />

            <AddProprietario
                show={modalAddProprietario}
                onHide={() => setModalAddProprietario(false)}
                handleAddProprietario={handleAddProprietario}
                handleStateAddTelefoneProprietario={handleStateAddTelefoneProprietario}
                handleDeleteTelefoneProprietario={handleDeleteTelefoneProprietario}
                updateTelefonePrioritarioProprietario={updateTelefonePrioritarioProprietario}
            />

            <UpdateProprietario
                show={modalUpdateProprietario}
                onHide={() => setModalUpdateProprietario(false)}
                editProprietario={editProprietario}
                handleUpdateProprietario={handleUpdateProprietario}
                handleStateAddTelefoneProprietario={handleStateAddTelefoneProprietario}
                handleDeleteTelefoneProprietario={handleDeleteTelefoneProprietario}
                stateUpdateTelefoneProprietario={stateUpdateTelefoneProprietario}
                updateTelefonePrioritarioProprietario={updateTelefonePrioritarioProprietario}
            />

            <AddContato
                show={modalAddContato}
                onHide={() => setModalAddContato(false)}
                handleAddContato={handleAddContato}
                TelefonePrioritario={TelefonePrioritario}
                idProprietario={idProprietario}
                stateUpdateTelefone={stateUpdateTelefone}
                updateTelefonePrioritario={updateTelefonePrioritario}
                handleDeleteTelefone={handleDeleteTelefone}
            />

            <UpdateContato
                show={modalUpdateContato}
                onHide={() => setModalUpdateContato(false)}
                editContato={editContato}
                handleUpdateContato={handleUpdateContato}
                TelefonePrioritario={TelefonePrioritario}
                stateUpdateTelefone={stateUpdateTelefone}
                updateTelefonePrioritario={updateTelefonePrioritario}
                handleDeleteTelefone={handleDeleteTelefone}
            />

            <AddVeiculo
                show={modalAddVeiculo}
                onHide={() => setModalAddVeiculo(false)}
                handleAddVeiculo={handleAddVeiculo}
                idProprietario={idProprietario}
            />

            <UpdateVeiculo
                show={modalUpdateVeiculo}
                onHide={() => setModalUpdateVeiculo(false)}
                editVeiculo={editVeiculo}
                handleUpdateVeiculo={handleUpdateVeiculo}
            />

            <AddLaudo
                show={modalAddLaudo}
                onHide={() => setModalAddLaudo(false)}
                handleAddLaudo={handleAddLaudo}
                idVeiculo={idVeiculo}
            />

            <ModalEscopos
                show={modalEscopos}
                onHide={() => setModalEscopos(false)}
            />

            <UpdateLaudo
                show={modalUpdateLaudo}
                onHide={() => setModalUpdateLaudo(false)}
                editLaudo={editLaudo}
                handleUpdateLaudo={handleUpdateLaudo}
            />

            <TopSearch>
                <Form ref={formRef} onSubmit={handleSubmitSearch}>
                    <div className="content">
                        <Input style={{ width: '100%' }} name="filtro" mask="" placeholder="Busque por: Nome, Cpf, Cnpj, Placa" />
                        {spinner === true ? (
                            <Button className="btn-not-houver" style={{ cursor: 'not-allowed' }} disabled>Buscar</Button>
                        ) : (
                            <Button type="submit">Buscar</Button>
                        )}
                    </div>
                </Form>

                <div style={{ display: 'flex', justifyContent: 'center' }} className="addProprietario">
                    <Button onClick={() => setModalAddProprietario(true)} style={{ width: 180 }}>Incluir Proprietario</Button>
                </div>
            </TopSearch>

            <Content>
                <div className="tableMain">
                    <div className="datatable-responsive-demo">
                        {spinner === true ? (
                            <Spinner animation="border" variant="primary" />
                        ) : (
                            <>
                                {dadosSearch.length > 0 && (
                                    <div className="card">
                                        <DataTable
                                            paginator
                                            className="p-datatable-responsive-demo"
                                            rows={10}
                                            value={dadosSearch}
                                            expandedRows={expandedRowsVeiculoContato}
                                            onRowToggle={(e) => setExpandedRowsVeiculoContato(e.data)}
                                            rowExpansionTemplate={rowExpansionTemplate}
                                            dataKey="id_proprietario"
                                        >
                                            <Column expander style={{ width: '100px' }} />
                                            <Column field="nome" header="Nome" body={ProprietarioBodyNome} sortable  />
                                            <Column field="cpfcnpj" header="CPF / CNPJ" body={ProprietarioBodyCPFCNPJ} />
                                            <Column field="email" header="E-mail" body={proprietarioBodyEmail} />
                                            <Column field="numero" header="Telefone" body={proprietarioBodyTelefone} />
                                            <Column
                                                field="action"
                                                header="Ações"
                                                body={actionProprietarioBodyTemplate}
                                            // className="proprietarioIconEdit"
                                            // style={{ maxWidth: '80px' }}
                                            />
                                        </DataTable>
                                    </div>
                                )}
                            </>
                        )}

                    </div>
                </div>
            </Content>
        </Container>
    )
}