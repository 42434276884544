import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Form as FormBootstrap, Row, Tab, Tabs } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';

import Modal from "react-bootstrap/Modal";

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import SelectUnform from '../../../components/SelectUnform';

import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { Container, RadioButton } from './styles';
import { estados } from '../../../utils/states';
import { api } from '../../../services/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { BiTrash } from 'react-icons/bi';

import { AddTelefone } from "./AddTelefone";
import { PickListSelect } from '../../../components/PickList';

export function AddProprietario({
    handleAddProprietario,
    handleStateAddTelefoneProprietario,
    handleDeleteTelefoneProprietario,
    updateTelefonePrioritarioProprietario,
    ...props
}) {
    const [type_CPF_CNPJ, setType_CPF_CNPJ] = useState('CPF');
    const [cpfC, setCPF] = useState('');
    const [valorcnpj, setCNPJ] = useState('');
    const [active, setActive] = useState(false);

    const [telefones, setTelefones] = useState([]);
    const [idProprietario, setIdProprietario] = useState('');

    const [modalAddTelefone, setModalAddTelefone] = useState(false);

    const formRef = useRef(null);
    const toast = useRef(null);

    const handleCep = async () => {
        try {
            const cepValue = formRef.current.getFieldValue("cep");
            const cep = cepValue.replace(/\.|-|\/|\\|\(|\)/g, '');

            const response = await api.get(`/cep?cep=${cep}`)

            const cepData = response.data

            formRef.current.setFieldValue("endereco", cepData.logradouro);
            formRef.current.setFieldValue("bairro", cepData.bairro);
            formRef.current.setFieldValue("complemento", cepData.complemento);
            formRef.current.setFieldValue("cidade", cepData.localidade);
            formRef.current.setFieldValue("uf", cepData.uf);
        } catch (err) {
            console.clear();
        }
    }

    const handleSubmit = useCallback(async (data) => {
        try {
            const cpfValid = data.cpf
            let valid = ''
            if (cpfValid) {
                valid = data.cpf
            } else {
                valid = data.cnpj
            }

            let newCPF = ''
            if (valid !== undefined) {
                newCPF = valid.replace(/\.|-|\/|\\|\(|\)/g, '');
            }

            if (valid !== undefined) {
                if (!cnpj.isValid(newCPF) && !cpf.isValid(newCPF)) {
                    return toast.current.show({ severity: 'warn', summary: 'Mensagem de Aviso!', detail: 'CPF/CNPJ Inválido!' });
                }
            }

            const schema = Yup.object().shape({
                nome: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            const newData = {
                bairro: data.bairro,
                cep: data.cep,
                cidade: data.cidade,
                complemento: data.complemento,
                cpfcnpj: newCPF,
                email: data.email,
                emailxmlnf: data.emailxmlnf,
                endereco: data.endereco,
                nome: data.nome,
                numero: data.numero,
                uf: data.uf,
            }
            // const newDataProprietario = {
            //     ...newData,
            //     telefones_proprietario: [],
            //     contatos: [],
            //     veiculos: []
            // }
            const response = await api.post('/proprietario', newData)

            const newDataProprietario = {
                ...response.data,
                telefones_proprietario: [],
                contatos: [],
                veiculos: []
            }
            await handleAddProprietario(newDataProprietario);

            setIdProprietario(response.data.id_proprietario)
            setActive(true)
            toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Proprietario criado com sucesso!' });
            // props.onHide();
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
        }
    }, [handleAddProprietario]);

    const showDDDNumber = (rowData) => {
        const formatDDD = rowData.ddd.replace(/(\d{1})(\d{1})/, "($1$2)")
        return (
            <React.Fragment>
                {formatDDD}
            </React.Fragment >
        )
    }

    const showNumberPhone = (rowData) => {
        const formatNumberPhone = rowData.numerotel.replace(/(\d{5})(\d{4})/, "$1-$2")
        return (
            <React.Fragment>
                {formatNumberPhone}
            </React.Fragment >
        )
    }

    const actionContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex' }}>
                    <span className="p-column-title">
                        <BiTrash size={25} onClick={() => handleDeleteRow(rowData)} />
                    </span>
                    {/* <span className="p-column-title">
                        <BiEdit size={25} onClick={() => handleModalEditRow(rowData)} />
                    </span> */}
                </div>
            </React.Fragment>
        );
    }

    async function handleAddTelefone(telefoneObj) {
        try {
            const teste = telefones.filter(mapped => mapped.prioritario !== 0)

            let t = telefoneObj
            if (teste.length === 1) {
                t = {
                    ...telefoneObj,
                    prioritario: 0
                }
            }
            const responseTel = await api.post('/telefone', {
                telefone: t,
                proprietario_id: idProprietario
            })
            const data = responseTel.data;

            handleStateAddTelefoneProprietario(data)
            setTelefones([...telefones, data])
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel criar o telefone, tente novamente' });
        }
    }

    async function handleDeleteRow(rowDataObjectDelete) {
        try {
            // const objIdContato = {
            //     id_proprietario: idProprietario
            // }
            await api.delete(`/telefone?id_telefone=${rowDataObjectDelete.id_telefone}`)
            handleDeleteTelefoneProprietario(rowDataObjectDelete)
            setTelefones(mapped => mapped.filter(filt => filt.id_telefone !== rowDataObjectDelete.id_telefone))
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel excluir o telefone' });
        }
    }

    async function handleTrue(rowData) {
        const newDataTelefone2 = telefones.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0
        }))
        await api.patch(`/telefone`, newDataTelefone2)
        const objIdContato = {
            id_proprietario: idProprietario
        }
        updateTelefonePrioritarioProprietario(newDataTelefone2, objIdContato)

        setTelefones(old => old.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0,
        })))
    }


    const actionPrioritario = (rowData) => {
        return (
            <React.Fragment>
                {rowData.prioritario === 0 ? (
                    <span onClick={() => handleTrue(rowData)}>
                        <ImCheckboxUnchecked />
                    </span>
                ) : (
                    <span>
                        <ImCheckboxChecked color="green" />
                    </span>
                )}
            </React.Fragment>
        )
    }

    function handleCloseModal() {
        setActive(false)
        setIdProprietario('')
        setTelefones([])
        props.onHide()
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Toast ref={toast} />

                <AddTelefone
                    show={modalAddTelefone}
                    onHide={() => setModalAddTelefone(false)}
                    handleAddTelefone={handleAddTelefone}
                />
                <Container >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h2 style={{ textAlign: 'center' }}>Adicionar Proprietario</h2>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormBootstrap as={Form} ref={formRef} onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <FormBootstrap.Label>Nome</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="nome"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ maxWidth: 150, marginTop: 10 }}>
                                    <RadioButton
                                        isActive={type_CPF_CNPJ === 'CPF' && '#03071e'}
                                        onClick={() => setType_CPF_CNPJ('CPF')}
                                    >
                                        <span>CPF</span>
                                    </RadioButton>
                                    <RadioButton
                                        isActive={type_CPF_CNPJ === 'CNPJ' && '#03071e'}
                                        onClick={() => setType_CPF_CNPJ('CNPJ')}
                                    >
                                        <span>CNPJ</span>
                                    </RadioButton>
                                </Col>
                                <Col style={{ maxWidth: '300px' }}>
                                    <FormBootstrap.Label>Escolha tipo CPF / CNPJ</FormBootstrap.Label>
                                    {type_CPF_CNPJ === 'CPF' ? (
                                        <FormBootstrap.Control
                                            as={InputMask}
                                            onChange={e => setCPF(e.target.value)}
                                            value={cpfC}
                                            name="cpf"
                                            placeholder="CPF"
                                            mask="999.999.999-99"
                                            maskChar=""
                                        />
                                    ) : (
                                        <FormBootstrap.Control
                                            as={InputMask}
                                            name="cnpj"
                                            placeholder="CNPJ"
                                            onChange={e => setCNPJ(e.target.value)}
                                            value={valorcnpj}
                                            mask="99.999.999/9999-99"
                                            maskChar=""
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ width: 200 }}>
                                    <FormBootstrap.Label>Endereço</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="endereco"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 120 }}>
                                    <FormBootstrap.Label>Número</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="numero"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 200 }}>
                                    <FormBootstrap.Label>Complemento</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="complemento"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Bairro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="bairro"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Cidade</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cidade"
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col style={{ maxWidth: '100px' }}>
                                    <FormBootstrap.Label>UF</FormBootstrap.Label>
                                    <SelectUnform name="uf" as="select" defaultValue="escolha..">
                                        {estados.map((estado) => (
                                            <option key={estado.value}>{estado.label}</option>
                                        ))}
                                    </SelectUnform>
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>CEP</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={InputMask}
                                        name="cep"
                                        mask="99999-999"
                                        maskChar=""
                                        onBlur={handleCep}
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>E-mail</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        type="email"
                                        as={Input}
                                        name="email"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>E-mail Nota Fiscal</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="emailxmlnf"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-2 mb-2">
                                    {active === true ? (
                                        <Button
                                            type="submit"
                                            disabled
                                        >Adicionar Proprietario</Button>
                                    ) : (
                                        <Button
                                            type="submit"

                                        >Adicionar Proprietario</Button>
                                    )}
                                </Col>
                            </Row>

                        </FormBootstrap>
                    </Modal.Body>
                    <Modal.Footer>
                        {active === true && (
                            <>
                                <Tabs
                                    defaultActiveKey="telefone"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="telefone" title="Telefone">
                                        <Row>
                                            <Col className="mt-4 mb-2">
                                                <Button
                                                    onClick={() => setModalAddTelefone(true)}
                                                >Adicionar Telefone</Button>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col style={{ maxWidth: 700 }}>
                                                <div className="datatable-rowexpansion-demo">
                                                    <div className="card">
                                                        <DataTable
                                                            paginator
                                                            scrollable scrollHeight="calc(100vh - 300px)"
                                                            rows={5}
                                                            value={telefones}
                                                        >
                                                            <Column field="ddd" header="DDD" style={{ width: 70 }} body={showDDDNumber} />
                                                            <Column field="numerotel" header="Número" body={showNumberPhone} />
                                                            <Column field="tipo_telefone" header="Tipo" style={{ width: 80 }} />
                                                            <Column field="prioritario" header="Prioritario" body={actionPrioritario} />
                                                            <Column
                                                                field="action"
                                                                header="Ações"
                                                                body={actionContatoBodyTemplate}
                                                                style={{ width: '80px' }} />
                                                        </DataTable>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="atividade" title="Atividades">
                                        <PickListSelect />
                                    </Tab>
                                </Tabs>

                                <Modal.Footer>
                                    <Button onClick={handleCloseModal}>Fechar</Button>
                                </Modal.Footer>
                            </>
                        )}
                    </Modal.Footer>
                </Container>
            </Modal>
        </>
    )
}