import React from 'react';
import { InputText } from 'primereact/inputtext';


export function AbaVeiculo() {
    return (
        <div>
            <div className="p-grid">
                <div className="p-col">
                    <div>Renavam</div>
                    <InputText value={'Renavam'} disabled />
                </div>
                <div className="p-col">
                    <div>Potencia</div>
                    <InputText value={'Potencia'} disabled />
                </div>
                <div className="p-col">
                    <div>CMT</div>
                    <InputText value={'CMT'} disabled />
                </div>
                <div className="p-col">
                    <div>Ano</div>
                    <InputText value={'Ano'} disabled />
                </div>
                <div className="p-col">
                    <div>PBT</div>
                    <InputText value={'PBT'} disabled />
                </div>
                <div className="p-col">
                    <div>Combustivel</div>
                    <InputText value={'Combustivel'} disabled />
                </div>
                <div className="p-col">
                    <div>Chassi</div>
                    <InputText value={'Chassi'} disabled />
                </div>
                <div className="p-col">
                    <div>Tara</div>
                    <InputText value={'Tara'} disabled />
                </div>
                <div className="p-col">
                    <div>N° Passageiros</div>
                    <InputText value={'N° Passageiros'} disabled />
                </div>
                <div className="p-col">
                    <div>Carroceria</div>
                    <InputText value={'Carroceria'} disabled />
                </div>
                <div className="p-col">
                    <div>Cor</div>
                    <InputText value={'Cor'} disabled />
                </div>
                <div className="p-col">
                    <div>Especie/Tipo</div>
                    <InputText value={'Especie/Tipo'} disabled />
                </div>
                <div className="p-col">
                    <div>Observação</div>
                    <InputText value={'Observação'} disabled />
                </div>
                <div className="p-col">
                    <div>Banco/Financeiro</div>
                    <InputText value={'Banco/Financeiro'} disabled />
                </div>
                <div className="p-col">
                    <div>Agregado</div>
                    <InputText value={'Agregado'} disabled />
                </div>
            </div>
            <div className="card mt-5">
                <div className="card-header">
                    PRODUTOS PERIGOSOS
                </div>
                <div className="card-body p-grid" >
                    <div className="p-col">
                        <div>7i/Car</div>
                        <InputText value={'7i/Car'} disabled />
                    </div>
                    <div className="p-col">
                        <div>Tipo de produto transporte</div>
                        <InputText value={'Tipo de produto transporte'} disabled />
                    </div>
                    <div className="p-col">
                        <div>Número de compartimentos do tanque</div>
                        <InputText value={'Número de compartimentos do tanque'} disabled />
                    </div>
                </div>
            </div>
        </div>
    )
}