import styled from 'styled-components';

export const Container = styled.div`
    .p-datatable .p-datatable-tbody > tr {
        &:hover {
            filter: brightness(0.9);
            cursor: pointer;
        }
    }
`;

export const Content = styled.div`
    margin: 32px 32px;

    section + section {
        margin-top: 20px;
    }

    .dataTable1 {

        .searchButtons {
            margin-bottom: 10px;

            button {
                transition: filter 0.2s;
            }
            
            button:hover {
                filter: brightness(0.9);
            }

            button + button {
                margin-left: 5px;
            }
        }
    }

    .icon1 {
            position: relative;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            /* background: #000; */

            svg {
                position: absolute;
                right: 10px;
                top: 15px;
                z-index: 10;
                transition: filter 0.2s;

                &:hover {
                    cursor: pointer;
                    filter: brightness(0.6);
                }
            }
        }
`;

export const ButtonAba = styled.button`
    border: none;
    background: ${(props) => props.isActive};
    color: ${(props) => props.isActive ? '#fff' : '#000'};

    height: 32px;
    width: 120px;

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.4);
`;

export const RadioButton = styled.button`
    background: ${(props) => props.isActive};
    color: ${(props) => props.isActive ? '#fff' : '#000'};

    height: 32px;
    width: 120px;

    border: none;
    border-radius: 5px;
`;