import styled from 'styled-components';

export const Container = styled.div`
    h1 {
        margin: 20px 0 0 32px;
    }

    .datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        display: none;
    }

@media screen and (max-width: 40em) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}
`;

export const RadioButton = styled.button`
    background: ${(props) => props.isActive};
    color: ${(props) => props.isActive ? '#fff' : '#000'};

    height: 32px;
    margin: 0 5px;
    padding: 5px 10px;
    /* width: 120px; */

    border: none;
    border-radius: 5px;
`;

export const ContextView = styled.div`
    margin: 32px;
    display: flex;
    justify-content: space-around;
    section {
        
        .content1 {
            max-width: 700px;

            display: flex;
            justify-content: space-between;
            
            h4 {
                width: 160px;
                font-family: Roboto;
                font-weight: 400;
                font-size: 1rem;
            }

            .firstTable {
                width: 100px;
            }
        }

        .buttonsRadio {
            
            .secondTable{
                max-width: 700px;
                margin-top: 10px;

                display: flex;
                align-items: center;
                justify-content: space-around;

                table {
                    width: 535px;
                    font-size: 0.9rem;
                    font-family: Roboto;
                }

                .Col1 {
                    /* margin: 32px; */
                }

                .Col2 {
                    width: 100px;
                    display: flex; 
                    flex-direction: column;
                }
            }
        }
    }
`;

export const ContextGraphyc = styled.div`
    margin: 0 auto;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    max-width: 1250px;

    .canvasjs-chart-canvas {
        /* display: none; */
    }

    .funnilItems {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* max-width: 600px; */
        width: 80%;
    }

    .funnil {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @keyframes testee {
            from {
                width: 0;
            }
            to {
                width: 100%
            }
        }

        h1 {
            font-family: Roboto;
            font-weight: 500;
            font-size: 1.5rem;
            width: 100%;
            line-height: 32px;
            /* border-bottom: 1px solid #80ed99; */
            text-align: center;
            margin: 10px auto;

        }

        section {
            /* border: 1px solid #000; */
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.8px 0;
            /* margin-bottom: 1.5px; */

            border-radius: 5px;

            border-bottom-right-radius: 1000;
            border-bottom-left-radius: 1000;

            div {
                display: flex;
                align-items: center;
            }

            h2 {
                font-family: Roboto;
                font-size: 1rem;
                margin: 0 auto;
                font-weight: 400;
                margin-right: 5px;
                text-align: center;
                color: #fff;
            }

            span {
                font-family: Roboto;
                font-size: 1rem;
                color: #fff;
                font-weight: 600;
            }

            &:hover {
                box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.3);
                cursor: pointer;
            }
        }
    }

    .Graphyc {
        max-width: 1250px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid red;
    }
`;

export const ContextCampanha = styled.div`
    margin: 20px 32px;
    display: flex;
    justify-content: space-around;

    .searchCampanhas {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 10px;

        h2 {
            font-family: Roboto;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }
`;

export const ContextGraphycFooter = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;