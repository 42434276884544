import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ProductService from './ProductService';

import { DataTableEditDemo } from './testeEdit';

export function AbaContatos() {
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);
    const isMounted = useRef(false);
    const productService = new ProductService();


    useEffect(() => {
        isMounted.current = true;
        productService.getProductsWithOrdersSmall().then(data => setProducts(data));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Orders for {data.name}</h5>
                <DataTableEditDemo />
            </div>
        );
    }

    return (
            <div className="card">
                <DataTable
                    className="p-datatable-responsive-demo"
                    scrollable
                    rows={2}
                    value={products}
                    expandedRows={expandedRows}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate} dataKey="id"
                >
                    <Column field="name" header="Contato" sortable />
                    <Column field="email" header="Email" sortable />
                    <Column field="telefone" header="Telefone" sortable />
                    <Column field="cargo" header="Cargo" sortable />
                </DataTable>
            </div>

    )
}