import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

export function AbaProprietario() {

    const [status, setStatus] = useState(null)
    const setStatusHandler = (e) => {
        setStatus(status = e.value) 
    }
    const statusOptions = [
        { name: 'Opção 1'},
        { name: 'Opção 2'},
        { name: 'Opção 3'},
        { name: 'Opção 4'},
        { name: 'Opção 5' }]

    return (
        <div className="p-grid">
            <div className="p-col mb-4">
                <div>Nome</div>
                <InputText value={'Nome'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>CPF/CNPJ</div>
                <InputText value={'CPF/CNPJ'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Tipo pessoa</div>
                <InputText value={'Tipo pessoa'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Data de Nascimento</div>
                <InputText value={'dd/yy/aaaa'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Email contato</div>
                <InputText value={'Email contato'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Email envio NFe</div>
                <InputText value={'Email envio NFe'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>CEP</div>
                <InputText value={'CEP'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Logradouro</div>
                <InputText value={'Logradouro'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Numero</div>
                <InputText value={'Numero'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Complemento</div>
                <InputText value={'Complemento'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Bairro</div>
                <InputText value={'Bairro'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Cidade</div>
                <InputText value={'Cidade'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>UF</div>
                <Dropdown value={status} options={statusOptions} onChange={setStatusHandler} optionLabel="name" placeholder="UF" />
            </div>
            <div className="p-col mb-4">
                <div>URL</div>
                <InputText value={'URL'} disabled />
            </div>
            <div className="p-col mb-4">
                <div>Blacklist</div>
                <div className="p-col p-grid">
                    <div className="p-field-checkbox mr-2">
                        <Checkbox inputId="lgpd" />
                        LGPD
                    </div>
                    <div className="p-field-checkbox mr-2">
                        <Checkbox inputId="sms" />
                        SMS
                    </div>
                    <div className="p-field-checkbox mr-2">
                        <Checkbox inputId="whatsApp" />
                        WhatsApp
                    </div>
                    <div className="p-field-checkbox mr-2">
                        <Checkbox inputId="telefone" />
                        Telefone
                    </div>
                </div>
            </div>
            <div className="p-col mb-4">
                <div>Atividade</div>
                <InputText value={'Atividade'} disabled />
            </div>
        </div>
    )
}