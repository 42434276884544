import React, { useState, useRef } from 'react';
import { MenuDashboard } from '../../components/MenuDashboard';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { api } from '../../services/api';
import { Container, Content } from './styles.js';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';

import { Spinner } from "react-bootstrap";

import { TabView, TabPanel } from 'primereact/tabview';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

// import ModalLoading from '../../components/Modal/ModalLoadingMessage'

// import ModalMessage from '../../components/Modal/ModalLoadingMessage'

// import { BiError } from 'react-icons/bi';

import Modal from 'react-bootstrap/Modal';


export function AtualizarDados() {

    const [dates, setDates] = useState(null);
    const [dates2, setDates2] = useState(null);

    const [dtIniConsulta, setDataInicial] = useState(null);
    const [dtFimConsulta, setDataFinal] = useState(null);
    const [laudosRet, setLaudosRet] = useState(null);
 


    const [onUpload] = useState(null);
    const chooseOptions = { label: 'Adicionar', icon: 'pi pi-fw pi-plus', className: 'p-button-success' };
    const uploadOptions = { label: 'Upload', icon: 'pi pi-upload', className: 'p-button-success' };
    const cancelOptions = { label: 'Cancelar', icon: 'pi pi-times' };

    const toast = useRef(null);
    const [spinner, setSpinner] = useState(false);

    // const [modalLoading, setModalLoading] = useState(false);

    // const [modalSuccess, setModalSuccess] = useState(false);
    // const [modalError, setModalError] = useState(false);


    const columns = [
        { field: 'id', header: 'Id' },
        { field: 'placa', header: 'Placa' },
        { field: 'ufPlaca', header: 'UfPlaca' },
        { field: 'numeroCSV', header: 'NumeroCSV' },
        { field: 'observacao', header: 'Observacao' },
        { field: 'renavam', header: 'Renavam' },
        { field: 'dataEmissaoCSV', header: 'DataEmissaoCSV' },
        { field: 'chassi', header: 'Chassi' },
    ];
    const handleSubmitLaudos = async () => {
        try {
            setSpinner(true);
            const response = await api.post("/registro/data", { laudosRet});
            if (response.data.falha) {
                toast.current.show({ severity: 'warn', summary: 'Atenção', detail: response.data.message });
                alert(`Atenção :`+ response.data.message);
            } else {
                if (response.data.quantidadeDeLaudos > 0) {
                    toast.current.show({ severity: 'success', summary: 'CRMITL', detail: `Foram Atualizados ${response.data.quantidadeDeLaudos} Laudos com Sucesso!` });
                    alert(`Foram Atualizados ${response.data.quantidadeDeLaudos} Laudos com Sucesso!` );
                }
            }
        } catch (err) {
            console.log(err)
            toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Erro ao Atualizar Dados!' });
        } finally {
            setSpinner(false);
        }

    }

    const handleSubmitSearch = async (data) => {
        try {
            setSpinner(true);
            setLaudosRet(null);
            // console.log(`Data inicio : ${dtIniConsulta})`);
            // console.log(`Data Fim : ${dtFimConsulta})`);

            const dataInicio = dtIniConsulta.toISOString();
            const dataFim = dtFimConsulta.toISOString();
            const response = await api.post("/buscaRegistro/data", { dataInicio, dataFim });


            if (response.data.falha) {
                toast.current.show({ severity: 'warn', summary: 'Atenção', detail: response.data.message });
            } else {
                if (response.data.quantidadeDeLaudos > 0) {
                    toast.current.show({ severity: 'success', summary: 'CRMITL', detail: `Foram Encontrado ${response.data.quantidadeDeLaudos} Laudos com Sucesso!` });
               
                    setLaudosRet(response.data.laudos);
                                   // console.log(laudosRet);
                             
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Nenhum dados encontrado para o período selecionado!' });

                    
                }



            }

        } catch (err) {
            console.log(err)
            toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Erro ao Atualizar Dados!' });

        } finally {

            setSpinner(false);
        }

    }

    const handleSubmitSearchOtimiza = async (data2) => {
        try {
            setSpinner(true);
            const dataInicio = dates2[0].toISOString();
            const dataFim = dates2[1].toISOString();
            const response = await api.post("/blackbox/otimiza", { dataInicio, dataFim });

            if (response.data.falha) {
                toast.current.show({ severity: 'warn', summary: 'Atenção', detail: response.data.message });
            } else {
                if (response.data.quantidadeDeLaudos > 0) {
                    toast.current.show({ severity: 'success', summary: 'CRMITL', detail: `Foram Atualizados ${response.data.quantidadeDeLaudos} Laudos com Sucesso!` });
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Nenhum dados encontrado para o período selecionado!' });
                }
            }
        } catch (err) {
            toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Erro ao Atualizar Dados!' });
        } finally {
            setSpinner(false);
        }
    } 

    const inputTextEditor = ( props, field) => {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange( props, e.target.value)} />;
    }

 const onEditorValueChange = ( props, value) => {
        let updatedProducts = [...props.value];
        updatedProducts[props.rowIndex][props.field] = value;
        setLaudosRet(updatedProducts);
    }
    

    const dataBodyTemplate = (rowData) => {
        let data=new Date(rowData?.dataEmissaoCSV).toLocaleDateString('pt-br')
        return data;
    }

    return (
        <Container>
            <MenuDashboard />
            <h2>Atualização de Dados</h2>
            <div className="p-grid">
                <Content>
                    <Toast ref={toast} />
                    <div>
                        <TabView >
                            <TabPanel header="WS Detran">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <div className="mt-3">Aqui você realiza a atualização dos dados do banco pegando<br />informações diretas do Web Service do DETRAN.</div>
                                    <div className="mt-4">
                                        
                                        {/* Data de Início e Fim da consulta
                                        <Calendar
                                            id="Calendar1"
                                            dateFormat="dd/mm/yy"
                                            selectionMode="range"
                                            value={dates}
                                            onChange={(e) => setDates(e.value)}
                                            showIcon
                                            placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                        >
                                        </Calendar> */}
                                         <div className="p-grid p-dir ml-1">
                                         <div className="p-col-1.5 align-items-center justify-content-center"style={{display: "flex"}}> Periodo Consulta :</div>
                                            <div className="p-col-2"> <Calendar dateFormat="dd/mm/yy" value={dtIniConsulta} onChange={(e) => setDataInicial(e.value)}></Calendar></div>
                                         <div className="p-col-0.5 align-items-center justify-content-center"style={{display: "flex"}}>a</div>
                                            <div className="p-col-2"> <Calendar dateFormat="dd/mm/yy" value={dtFimConsulta} onChange={(e1) => setDataFinal(e1.value)}></Calendar></div>
                                        
                                            </div></div>
                                    <div className="mt-1"> 
                                    <div className="p-grid p-dir">
                                    <div className="p-col-2 "> <Button className="p-button-primary mt-3 " label="Buscar Dados" onClick={handleSubmitSearch} /> </div>
                                    <div className="p-col-2">   <Button className="p-button-success mt-3" label="Atualizar Dados" onClick={handleSubmitLaudos} /></div>
                                    </div>
                                    </div>
                                </div>
                                </div >


                                {/* <DataTable value={laudosRet} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">
                                <Column field="id" header="Id"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                <Column field="placa" header="Placa"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                <Column field="ufPlaca" header="UF Placa"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                <Column field="numeroCSV" header="numeroCSV"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                 <Column field="observacao" header="observacao"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                <Column field="renavam" header="renavam"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                                <Column field="dataEmissaoCSV" header="dataEmissaoCSV"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column> 
                                <Column field="chassi" header="chassi"   editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column> 

                                </DataTable> */}


<div className="datatable-editing-demo">
            <Toast ref={toast} />

            <div className="card p-fluid">
                <DataTable value={laudosRet} editMode="cell" className="editable-cells-table" responsiveLayout="scroll">
                    {
                        columns.map(({ field, header }) => {
                            return <Column key={field} field={field} header={header} style={{ width: '25%' }} body={field === 'dataEmissaoCSV' && dataBodyTemplate}
                            editor={(props) => inputTextEditor( props, field)} /> 
                        })
                    }
                </DataTable>
            </div> </div>
                                
                            </TabPanel>
                            <TabPanel header="ERP Otimiza">
                                <div className="p-col-12">
                                    <div className="mt-3">Aqui você realiza a atualização dos dados do banco pegando<br />informações diretas do ERP Otimiza.</div>
                                    <div className="mt-4">Data de Início e Fim da consulta</div>
                                    <div>
                                        <Calendar
                                            id="Calendar2"
                                            dateFormat="dd/mm/yy"
                                            selectionMode="range"
                                            value={dates2}
                                            onChange={(e) => setDates2(e.value)}
                                            showIcon
                                            placeholder="dd/mm/yyyy - dd/mm/yyyy"
                                        >
                                        </Calendar>
                                    </div>
                                    <div>
                                        <Button className="p-button-success mt-3" label="Atualizar Dados" onClick={handleSubmitSearchOtimiza} />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel header="Importar dados via Planilha CSV">
                                <div className="p-col-12">
                                    <div className="mt-3">Você também pode importar dados realizando upload por uma planilha CSV.</div>
                                    <FileUpload name="upload[]"
                                        className="mt-4"
                                        url=""
                                        onUpload={onUpload}
                                        multiple
                                        maxFileSize={10000000}
                                        emptyTemplate={<p className="p-m-0">Arraste e solte arquivos aqui para realizar o upload.</p>}
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        accept={".csv"}
                                    />
                                </div>
                            </TabPanel>
                        </TabView>

                    </div>
                </Content>
            </div>
           
            <Modal
            
                centered={true}
                show={spinner}
                // onHide={() => setSpinner(false)}
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                size='sm'
                keyboard={false}
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <Spinner animation="border" variant="primary" /> Aguarde...
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Estamos procesando sua requisição!
                        {spinner}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <p> *** CRMITL *** </p>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}
