import React, { useRef } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/Input';

import { Container } from './styles';
import SelectUnform from '../../../../components/SelectUnform';

export function UpdateTelefone({ isOpen, setIsOpen, rowEditTel, handleUpdateTelefone }) {
    const toast = useRef(null);
    const formRef = useRef(null);

    const optionsTipo = [
        { value: "movel", label: "Movel" },
        { value: "fixo", label: "Fixo" }
    ]

    async function handleEditTelefone(data) {
        await handleUpdateTelefone(data)
        setIsOpen(false)
    }


    return (
        <>
            <Toast ref={toast} />
            <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                <Container>
                    <AiOutlineClose onClick={() => setIsOpen(false)} size={25} color="red" />
                    <h2 style={{ textAlign: 'center' }}>Atualizar Telefone</h2>

                    <FormBootstrap
                        as={Form}
                        ref={formRef}
                        onSubmit={handleEditTelefone}
                        initialData={rowEditTel}
                    >
                        <Row>
                            <Col>
                                <div
                                    style={{ display: "flex" }}
                                    // key={index}
                                    className="schedule-item mb-2"
                                >
                                    <FormBootstrap.Control
                                        style={{ maxWidth: 60 }}
                                        as={Input}
                                        className="mr-2"
                                        placeholder="DDD"
                                        name="ddd"
                                        label="Das"
                                        type="text"
                                    // value={scheduleItem.ddd}
                                    // onChange={(e) =>
                                    //     setScheduleItemValue(index, "ddd", e.target.value)
                                    // }
                                    />
                                    <FormBootstrap.Control
                                        as={Input}
                                        placeholder="Telefone"
                                        name="numerotel"
                                        label="Até"
                                        type="text"
                                    // value={scheduleItem.telefone}
                                    // onChange={(e) =>
                                    //     setScheduleItemValue(index, "telefone", e.target.value)
                                    // }
                                    />

                                    {/* <Select
                                        style={{ width: 200 }}
                                        name="tipo"
                                        label="Tipo"
                                        type="select"
                                        // value={scheduleItem.tipo}
                                        // onChange={(e) =>
                                        //     setScheduleItemValue(index, "tipo", e.target.value)
                                        // }
                                        options={[
                                            { value: "", label: "" },
                                            { value: "movel", label: "Movel" },
                                            { value: "fixo", label: "Fixo" }
                                        ]}
                                    /> */}

                                    <SelectUnform name="tipo_telefone" options={optionsTipo} >
                                        {optionsTipo.map(tipo => (
                                            <option key={tipo.value}>{tipo.label}</option>
                                        ))}
                                    </SelectUnform>
                                </div>
                            </Col>
                        </Row>
                        <Button type="submit" className="mt-2">Atualizar</Button>
                    </FormBootstrap>
                </Container>
            </Modal>
        </>
    )
}