import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export function ModalEscopos({ ...props }) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Escopos
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="tableMain">
                    <div className="datatable-responsive-demo">
                        <div className="card">
                            <DataTable
                                paginator
                                rows={5}
                            >
                                <Column field="nome" header="Nome" />
                                <Column field="status" header="Status" />
                            </DataTable>
                            {/* <DataTablele
                                paginator
                                className="p-datatable-responsive-demo"
                                rows={5}
                                value={dadosSearch}
                                expandedRows={expandedRowsVeiculoContato}
                                onRowToggle={(e) => setExpandedRowsVeiculoContato(e.data)}
                                rowExpansionTemplate={rowExpansionTemplate}
                                dataKey="id_proprietario"
                            >
                                <Column expander style={{ width: '100px' }} />
                                <Column field="nome" header="Nome" body={ProprietarioBodyNome} />
                                <Column field="cpfcnpj" header="CPF / CNPJ" body={ProprietarioBodyCPFCNPJ} />
                                <Column field="email" header="E-mail" body={proprietarioBodyEmail} />
                                <Column field="numero" header="Telefone" body={proprietarioBodyTelefone} />
                                            
                            </DataTable> */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => props.onHide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}