import React, { useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { AiOutlineWarning } from 'react-icons/ai';
import { MenuDashboard } from '../../components/MenuDashboard';

// import { Chart } from 'primereact/chart';

import { Form as FormBootstrap, Row, Col } from 'react-bootstrap';

import { ModalCampanha } from './ModalCampanha';
import { ModalAtendimento } from './ModalAtendimento';

import { Container, Content, RadioButton, ButtonAba } from './styles';
// import Input from '../../components/Input';
import Chart from 'react-google-charts';

export function Campanha() {
    // const chartData = {
    //     labels: ['A', 'B', 'C'],
    //     datasets: [
    //         {
    //             data: [300, 50, 100],
    //             backgroundColor: [
    //                 "#42A5F5",
    //                 "#66BB6A",
    //                 "#FFA726"
    //             ],
    //             hoverBackgroundColor: [
    //                 "#64B5F6",
    //                 "#81C784",
    //                 "#FFB74D"
    //             ]
    //         }
    //     ]
    // }

    // const lightOptions = {
    //     legend: {
    //         labels: {
    //             fontColor: '#495057'
    //         }
    //     }
    // };

    const [type, setType] = useState('todos');
    const [aba, setAba] = useState('operacao');

    const [modalCampanha, setModalCampanha] = useState(false);
    const [modalAtendimento, setModalAtendimento] = useState(false);

    const dadosOperacao = []

    const dadosAtendimento = [
        {
            placa: 'dre3-452d',
            proprietario: 'Silvio',
            status: 'ativo',
            qualificacao: '',
        }
    ]

    const NomeCampanhaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Nome campanha</span> */}
                {rowData.nomeCampanha}
            </React.Fragment>
        );
    }

    const StatusBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Status</span> */}
                {rowData.status}
            </React.Fragment>
        );
    }

    /** Table2 */
    const PlacaBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Placa</span> */}
                {rowData.placa}
            </React.Fragment>
        );
    }

    const ProprietarioBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <span className="p-column-title">Proprietario</span> */}
                {rowData.proprietario}
            </React.Fragment>
        );
    }

    // const Status = (rowData) => {
    //     return (
    //         <React.Fragment>
    //             <span className="p-column-title">Proprietario</span>
    //             {rowData.proprietario}
    //         </React.Fragment>
    //     );
    // }

    const QualificacaoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">nenhum dado</span>
                {/* {rowData.qualificacao} */}
            </React.Fragment>
        );
    }

    const NenhumDadoEncontradoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <span className="p-column-title">Nenhum dado foi encontrado</span>
                {/* {rowData.qualificacao} */}
            </React.Fragment>
        );
    }

    return (
        <Container>
            <MenuDashboard />

            <ModalCampanha
                show={modalCampanha}
                onHide={() => setModalCampanha(false)}
            />

            <ModalAtendimento
                header="Atendimento"
                visible={modalAtendimento}
                onHide={() => setModalAtendimento(false)}
            />

            <Content>
                <section className="dataTable1">
                    <div className="searchButtons">
                        <RadioButton
                            isActive={type === 'aberto' && '#03071e'}
                            onClick={() => setType('aberto')}
                        >
                            <span>Aberto</span>
                        </RadioButton>

                        <RadioButton
                            isActive={type === 'fechado' && '#03071e'}
                            onClick={() => setType('fechado')}
                        >
                            <span>Fechado</span>
                        </RadioButton>

                        <RadioButton
                            isActive={type === 'todos' && '#03071e'}
                            onClick={() => setType('todos')}
                        >
                            <span>Todos</span>
                        </RadioButton>
                    </div>
                    <div className="datatable-responsive-demo">
                        <div className="card icon1">
                            <div>
                                <IoIosAddCircleOutline
                                    size={32}
                                    color="green"
                                    onClick={() => setModalCampanha(true)}
                                />
                            </div>
                            <DataTable
                                value={() => { }}
                                className="p-datatable-responsive-demo"
                                paginator
                                rows={10}
                            >
                                <Column field="nomeCampanha" header="Nome campanha" body={NomeCampanhaBodyTemplate} />
                                <Column field="status" header="Status" body={StatusBodyTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </section>

                <section>
                    <div>
                        <ButtonAba
                            isActive={aba === 'operacao' && '#03071e'}
                            onClick={() => setAba('operacao')}
                        >Operação</ButtonAba>
                        <ButtonAba
                            isActive={aba === 'atendimento' && '#03071e'}
                            onClick={() => setAba('atendimento')}
                        >Atendimento</ButtonAba>
                        <ButtonAba
                            isActive={aba === 'estatistica' && '#03071e'}
                            onClick={() => setAba('estatistica')}
                        >Estatistica</ButtonAba>
                    </div>
                    {aba === 'operacao' && (
                        <div className="datatable-responsive-demo">
                            <div className="card">
                                {dadosOperacao.length > 0 ? (
                                    <DataTable value={dadosOperacao} className="p-datatable-responsive-demo" paginator rows={10}>
                                        <Column field="nomeCampanha" header="Nome campanha" body={NomeCampanhaBodyTemplate} />
                                        <Column field="status" header="Status" body={StatusBodyTemplate} />
                                    </DataTable>
                                ) : (
                                    <DataTable value={dadosAtendimento} className="p-datatable-responsive-demo" paginator rows={10}>
                                        <Column header={<AiOutlineWarning size={25} color="#fb6107" />} body={NenhumDadoEncontradoBodyTemplate} />
                                    </DataTable>
                                )}
                            </div>
                        </div>
                    )}
                    {aba === 'atendimento' && (
                        <div className="datatable-responsive-demo">
                            <div className="card">
                                {dadosAtendimento.length > 0 ? (
                                    <DataTable
                                        value={dadosAtendimento}
                                        onRowClick={() => setModalAtendimento(true)}
                                        className="p-datatable-responsive-demo"
                                        paginator
                                        rows={10}
                                    >
                                        <Column field="placa" header="Placa" body={PlacaBodyTemplate} />
                                        <Column field="proprietario" header="Proprietario" body={ProprietarioBodyTemplate} />
                                        <Column field="status" header="Status" body={StatusBodyTemplate} />
                                        <Column field="qualificacao" header="Qualificação" body={QualificacaoBodyTemplate} />
                                    </DataTable>
                                ) : (
                                    <DataTable value={dadosAtendimento} className="p-datatable-responsive-demo" paginator rows={10}>
                                        <Column body={NenhumDadoEncontradoBodyTemplate} />
                                    </DataTable>
                                )}
                            </div>
                        </div>
                    )}
                    {aba === 'estatistica' && (
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginTop: 5,
                        }}>
                            <div style={{
                                maxWidth: 800,
                                border: '1px solid #ccc',
                                padding: 32,
                            }}>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Label>Nome Campanha: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="nome-campanha"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Label>Inicio: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="inicio"
                                            type="date"
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>Fim: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="fim"
                                            type="date"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Label>Status: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="status"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ maxWidth: 120 }}>
                                        <FormBootstrap.Label>N° Veiculos: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="veiculos"
                                            style={{ maxWidth: 100 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>Potencial: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="potencia"
                                            style={{ maxWidth: 200 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>Valor Total: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="potencia"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ maxWidth: 120 }}>
                                        <FormBootstrap.Label >Contatados: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="contatos"
                                            style={{ maxWidth: 100 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label >Agendados: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="agendados"
                                            style={{ maxWidth: 100 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>TX:% </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="agendados"
                                            style={{ maxWidth: 100 }}
                                        />
                                    </Col>
                                </Row>
                                <Row>


                                </Row>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Label>N° Veiculos Novos: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="veiculos-novos"
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>N° Veiculos Recorrentes: </FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            // as={Input}
                                            name="Recorrentes"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {/* <div className="card">
                                <Chart type="pie" data={chartData} options={lightOptions} />
                            </div> */}
                            <div>
                                <div style={{ display: 'flex', }}>
                                    <Chart
                                        width={'600px'}
                                        height={'400px'}
                                        chartType="PieChart"
                                        loader={<div>Loading Chart</div>}
                                        data={[
                                            ['Task', 'Hours per Day'],
                                            ['Work', 11],
                                            ['Eat', 2],
                                            ['Commute', 2],
                                            ['Watch TV', 2],
                                            ['Sleep', 7],
                                        ]}
                                        options={{
                                            title: 'Status Atendimento',
                                        }}
                                        rootProps={{ 'data-testid': '1' }}
                                    />
                                    <div className="datatable-responsive-demo" style={{ maxWidth: 600 }}>
                                        <div className="card">
                                            <DataTable value={dadosOperacao} className="p-datatable-responsive-demo" paginator rows={10}>
                                                <Column field="nomeCampanha" header="Quantidade" body={NomeCampanhaBodyTemplate} />
                                                <Column field="nomeCampanha" header="%" body={NomeCampanhaBodyTemplate} style={{ width: 100 }} />
                                                <Column field="nomeCampanha" header="Descrição" body={NomeCampanhaBodyTemplate} />
                                                {/* <Column field="status" header="Status" body={StatusBodyTemplate} /> */}
                                            </DataTable>
                                            {/* {dadosOperacao.length > 0 ? (
                                            <DataTable value={dadosOperacao} className="p-datatable-responsive-demo" paginator rows={10}>
                                                <Column field="nomeCampanha" header="Nome campanha" body={NomeCampanhaBodyTemplate} />
                                                <Column field="status" header="Status" body={StatusBodyTemplate} />
                                            </DataTable>
                                        ) : (
                                            <DataTable value={dadosAtendimento} className="p-datatable-responsive-demo" paginator rows={10}>
                                                <Column header={<AiOutlineWarning size={25} color="#fb6107" />} body={NenhumDadoEncontradoBodyTemplate} />
                                            </DataTable>
                                        )} */}
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable-responsive-demo" style={{ maxWidth: 600 }}>
                                    <div className="card">
                                        <DataTable title="Operação" value={dadosOperacao} className="p-datatable-responsive-demo" paginator rows={10}>
                                            <Column field="nomeCampanha" header="Operador" body={NomeCampanhaBodyTemplate} />
                                            <Column field="nomeCampanha" header="N° Veiculos Atendimento" body={NomeCampanhaBodyTemplate} />
                                            <Column field="nomeCampanha" header="Agendados" body={NomeCampanhaBodyTemplate} />
                                            <Column field="nomeCampanha" header="%" body={NomeCampanhaBodyTemplate} style={{ width: 100 }} />
                                            {/* <Column field="status" header="Status" body={StatusBodyTemplate} /> */}
                                        </DataTable>
                                        {/* {dadosOperacao.length > 0 ? (
                                            <DataTable value={dadosOperacao} className="p-datatable-responsive-demo" paginator rows={10}>
                                                <Column field="nomeCampanha" header="Nome campanha" body={NomeCampanhaBodyTemplate} />
                                                <Column field="status" header="Status" body={StatusBodyTemplate} />
                                            </DataTable>
                                        ) : (
                                            <DataTable value={dadosAtendimento} className="p-datatable-responsive-demo" paginator rows={10}>
                                                <Column header={<AiOutlineWarning size={25} color="#fb6107" />} body={NenhumDadoEncontradoBodyTemplate} />
                                            </DataTable>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </section>
            </Content>
        </Container>
    )
}