import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 32px;

    h1 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.5rem;
    }
    
    svg {
        position: absolute;
        right: 0;
        top: 0;

        transition: filter 0.2s;

        &:hover {
            cursor: pointer;
            filter: brightness(0.8);
        }
    }
`;