import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 32px;

    button.close {
        color: red;
    }
    
    h2 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.8rem;
    }

    .button {
        display: flex;
        justify-content: center;

        button {
            height: 40px;
            border: none;
            width: 100%;

            font-family: Roboto;

            background: #99d98c;

            transition: filter 0.2s;

            &:hover {
                filter: brightness(0.9);
            }
        }
    }

    svg {
        position: absolute;
        right: 0;
        top: 0;

        transition: filter 0.2s;

        &:hover {
            cursor: pointer;
            filter: brightness(0.8);
        }
    }

    .gridNumbers {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;