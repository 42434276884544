import React, { useState } from 'react';

import { Form as FormBootstrap } from "react-bootstrap";

import { MenuDashboard } from '../../components/MenuDashboard';

import FunnelGrap from "./FunnelGrap";

import {
    Container,
    ContextView,
    RadioButton,
    ContextGraphyc,
    ContextCampanha,
    ContextGraphycFooter
} from './styles';
import { Table } from 'react-bootstrap';
import Chart from 'react-google-charts';

// import Arrow from "../../assets/arrow2.png"
// import { Funnel } from '../../components/Funnel';

export function Dashboard() {
    // const [type, setType] = useState('todos');
    const [option, setOption] = useState('Realizado no IQT')
    const [optionCampanha, setOptionCampanha] = useState('TODAS')

    const campanhasNome = [
        { id: 1, nome: "Nome 1" },
        { id: 2, nome: "Nome 2" },
    ]

    const escopos = [
        { municipios: "teste2", cadastrados: 123, realizados: 40, aRealizar: 100 }
    ]

    const datas = [
        { date: "abr/2021" },
        { date: "mar/2021" },
        { date: "fev/2021" },
        { date: "jan/2021" },
        { date: "dez/2021" },
        { date: "nov/2021" },
        { date: "out/2021" },
        { date: "set/2021" },
        { date: "ago/2021" },
        { date: "ago/2021" },
        { date: "jul/2021" },
        { date: "jun/2021" },
    ]

    const valuesTable = [
        {
            Month1: 293,
            Month2: 66,
            Month3: 95,
            Month4: 54,
            Month5: 27,
            Month6: 23,
            Month7: 8,
            Month8: 20
        },
        {
            Month1: 343,
            Month2: 56,
            Month3: 30,
            Month4: 90,
            Month5: 3,
            Month6: 17,
            Month7: 17,
            Month8: 61
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
        {
            Month1: 458,
            Month2: 84,
            Month3: 47,
            Month4: 94,
            Month5: 56,
            Month6: 50,
            Month7: 69,
            Month8: 89
        },
    ]

    const campanhas = [
        {
            name: "GNV ABRIL 2021",
            periodo: "01/01/2021 a 31/01/2021",
            numVeiculos: "426",
            numInspecao: 51,
            txConversao: "12,00%",
            valor: 17.892,
        },
        {
            name: "Rochas maio 2021",
            periodo: "01/01/2021 a 31/01/2022",
            numVeiculos: "772",
            numInspecao: 124,
            txConversao: "16,00%",
            valor: 92.640,
        },
        {
            name: "ANTT jan 2021",
            periodo: "01/01/2021 a 31/01/2023",
            numVeiculos: "795",
            numInspecao: 159,
            txConversao: "20,00%",
            valor: 79.500,
        },
    ]


    return (
        <Container>
            <MenuDashboard />

            {/* <h1 style={{
                // width: '100%',
                height: 'calc(100vh - 90px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>Dashboard...</h1> */}
            <span class="label label-danger">Versão: 2023.1.0.1</span>

            <ContextView>
                <section>
                    <div className="content1">
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginTop: 20,
                        }}>
                            <h4>Veiculos Cadastrados</h4>
                            <h4>Já realizados</h4>
                        </div>

                        <Table size="sm" bordered striped className="firstTable">
                            <thead>
                                <tr>
                                    <th>Geral</th>
                                    <th>GNV</th>
                                    <th>Escolar</th>
                                    <th>Rochas</th>
                                    <th>ANTT</th>
                                    <th>MERCOSUL</th>
                                    <th>CETURB</th>
                                    <th>OUTROS</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>36.050</td>
                                    <td>7.166</td>
                                    <td>3.095</td>
                                    <td>3.612</td>
                                    <td>2.905</td>
                                    <td>5.486</td>
                                    <td>6.901</td>
                                    <td>6.885</td>
                                </tr>
                                <tr>
                                    <td>13.396</td>
                                    <td>1.751</td>
                                    <td>1.350</td>
                                    <td>1.701</td>
                                    <td>1.236</td>
                                    <td>1.631</td>
                                    <td>3.689</td>
                                    <td>2.038</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="buttonsRadio">
                        <div className="buttons">
                            <RadioButton
                                isActive={option === 'Realizado no IQT' && '#03071e'}
                                onClick={() => setOption('Realizado no IQT')}
                            >Realizado</RadioButton>
                            <RadioButton
                                isActive={option === 'vencendo no mês' && '#03071e'}
                                onClick={() => setOption('vencendo no mês')}
                            >Vencendo no mês</RadioButton>
                            <RadioButton
                                isActive={option === 'Realizado no mês' && '#03071e'}
                                onClick={() => setOption('Realizado no mês')}
                            >Realizado no mês</RadioButton>
                        </div>

                        <div className="secondTable">
                            <div className="Col1">
                                <span>Realizado</span>
                            </div>
                            <div className="Col2">
                                <Table
                                    bordered
                                    striped
                                    size="sm"
                                    style={{ width: '100%', marginBottom: 45 }}
                                >
                                    <tbody>
                                        {datas.map(data => (
                                            <tr>
                                                <td>{data.date}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <Table size="sm" bordered striped>
                                <tbody>
                                    {valuesTable.map(value => (
                                        <tr>
                                            <td>{value.Month1}</td>
                                            <td>{value.Month2}</td>
                                            <td>{value.Month3}</td>
                                            <td>{value.Month4}</td>
                                            <td>{value.Month5}</td>
                                            <td>{value.Month6}</td>
                                            <td>{value.Month7}</td>
                                            <td>{value.Month8}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>


                </section>

                <div className="content2">
                    <div className="mb-2" style={{ maxWidth: '100%' }}>
                        <FormBootstrap.Label>Escopo</FormBootstrap.Label>
                        <FormBootstrap.Control as="select" defaultValue="escolha..">
                            {campanhasNome.map((campanha) => (
                                <option key={campanha.id}>{campanha.nome}</option>
                            ))}
                        </FormBootstrap.Control>
                    </div>
                    <Table size="sm" bordered striped>
                        <thead>
                            <tr>
                                <th>Municipios</th>
                                <th>Cadastrados</th>
                                <th>Realizados</th>
                                <th>A Realizar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {escopos.map(escopo => (
                                <tr>
                                    <td>{escopo.municipios}</td>
                                    <td>{escopo.cadastrados}</td>
                                    <td>{escopo.realizados}</td>
                                    <td>{escopo.aRealizar}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </ContextView>
            <ContextGraphyc>
                <Chart
                    width={600}
                    height={300}
                    chartType="ColumnChart"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Data', 'Agendamentos'],
                        ['jun/2020', 500],
                        ['jul/2020', 280],
                        ['ago/2020', 420],
                        ['set/2020', 400],
                        ['out/2020', 200],
                        ['nov/2020', 380],
                        ['dez/2020', 410],
                        ['jan/2021', 450],
                        ['fez/2021', 480],
                        ['mar/2021', 480],
                        ['abr/2021', 480],
                    ]}
                    options={{
                        title: 'Agendamentos Mensais',
                        chartArea: { width: '80%' },
                        hAxis: {
                            title: '',
                            minValue: 0,
                        },
                        vAxis: {
                            title: '',
                        },
                    }}
                    legendToggle
                />

                <div style={{ width: 500, position: 'relative' }}>
                    <div style={{
                        position: 'absolute',
                        height: 20,
                        width: 60,
                        bottom: 0,
                        zIndex: 10,
                        background: "#fff"
                    }}></div>
                    <FunnelGrap />
                    <div style={{
                        position: 'absolute',
                        height: 20,
                        width: 65,
                        bottom: 0,
                        right: 0,
                        zIndex: 10,
                        background: "#fff"
                    }}></div>
                </div>
            </ContextGraphyc>
            <ContextCampanha>
                <div>
                    <div className="searchCampanhas">
                        <h2>Campanhas</h2>
                        <div className="buttonsCampanhas">
                            <RadioButton
                                isActive={optionCampanha === 'ATIVA' && '#03071e'}
                                onClick={() => setOptionCampanha('ATIVA')}
                            >ATIVA</RadioButton>
                            <RadioButton
                                isActive={optionCampanha === 'FECHADA' && '#03071e'}
                                onClick={() => setOptionCampanha('FECHADA')}
                            >FECHADA</RadioButton>
                            <RadioButton
                                isActive={optionCampanha === 'TODAS' && '#03071e'}
                                onClick={() => setOptionCampanha('TODAS')}
                            >TODAS</RadioButton>
                        </div>
                    </div>
                    <Table size="sm" bordered striped>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Periodo</th>
                                <th>N° Veiculos</th>
                                <th>N° Inspeções</th>
                                <th>TX Conversão</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {campanhas.map(campanha => (
                                <tr>
                                    <td>{campanha.name}</td>
                                    <td>{campanha.periodo}</td>
                                    <td>{campanha.numVeiculos}</td>
                                    <td>{campanha.numInspecao}</td>
                                    <td>{campanha.txConversao}</td>
                                    <td>{campanha.valor}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </ContextCampanha>
            <ContextGraphycFooter>
                <div className="graph1">
                    <div style={{ marginBottom: 80 }}></div>
                    <Chart
                        width={'600px'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Task', 'Hours per Day'],
                            ['Não vai fazer agora', 11],
                            ['Vai fazer em outra ITL', 2],
                            ['Já realizou a inspeção', 2],
                            ['Sem contato', 2],
                            ['Preço alto', 7],
                            ['Atendimento ruim', 7],
                            ['Demora na inspeção IQT', 7],
                            ['Sem recurso', 7],
                            ['Vendeu o veiculo', 7],
                            ['Veiculo em manutenção', 7],
                            ['Demora/Reprovação no IQT', 7],
                            ['Outro', 7],
                        ]}
                        options={{
                            title: 'Não Conversão Geral',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />

                    <div className="mt-2">
                        <span className="mr-2">Ranking Operadores no periodo</span>
                        <input type="date" className="mr-2" />
                        <input type="date" />
                    </div>
                    <Table size="sm" bordered striped className="mt-2">
                        <thead>
                            <tr>
                                <th>Operador</th>
                                <th>Atendimentos</th>
                                <th>Agendamentos</th>
                                <th>Conversão</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Nome 1</td>
                                <td>807</td>
                                <td>335</td>
                                <td>42%</td>
                            </tr>
                            <tr>
                                <td>Nome 2</td>
                                <td>806</td>
                                <td>117</td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>Nome 3</td>
                                <td>235</td>
                                <td>123</td>
                                <td>52%</td>
                            </tr>
                            <tr>
                                <td>Nome 4</td>
                                <td>878</td>
                                <td>580</td>
                                <td>66%</td>
                            </tr>
                            <tr>
                                <td>Nome 5</td>
                                <td>897</td>
                                <td>251</td>
                                <td>28%</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div>
                    <div className="mb-2" style={{ maxWidth: '100%' }}>
                        <FormBootstrap.Label>Nome da Campanha</FormBootstrap.Label>
                        <FormBootstrap.Control as="select" defaultValue="escolha..">
                            {campanhasNome.map((campanha) => (
                                <option key={campanha.id}>{campanha.nome}</option>
                            ))}
                        </FormBootstrap.Control>
                    </div>
                    <Chart
                        width={'600px'}
                        height={'400px'}
                        chartType="PieChart"
                        loader={<div>Loading Chart</div>}
                        data={[
                            ['Task', 'Hours per Day'],
                            ['Não vai fazer agora', 11],
                            ['Vai fazer em outra ITL', 2],
                            ['Já realizou a inspeção', 2],
                            ['Sem contato', 2],
                            ['Preço alto', 7],
                            ['Atendimento ruim', 7],
                            ['Demora na inspeção IQT', 7],
                            ['Sem recurso', 7],
                            ['Vendeu o veiculo', 7],
                            ['Veiculo em manutenção', 7],
                            ['Demora/Reprovação no IQT', 7],
                            ['Outro', 7],
                        ]}
                        options={{
                            title: 'Não Conversão Campanha',
                        }}
                        rootProps={{ 'data-testid': '1' }}
                    />
                    <div className="mt-2">
                        <span className="mr-2">Ranking Operadores da campanha</span>
                    </div>
                    <Table size="sm" bordered striped className="mt-2">
                        <thead>
                            <tr>
                                <th>Operador</th>
                                <th>Atendimentos</th>
                                <th>Agendamentos</th>
                                <th>Conversão</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Nome 1</td>
                                <td>807</td>
                                <td>335</td>
                                <td>42%</td>
                            </tr>
                            <tr>
                                <td>Nome 2</td>
                                <td>855</td>
                                <td>232</td>
                                <td>27%</td>
                            </tr>
                            <tr>
                                <td>Nome 3</td>
                                <td>702</td>
                                <td>49</td>
                                <td>7%</td>
                            </tr>
                            <tr>
                                <td>Nome 4</td>
                                <td>611</td>
                                <td>346</td>
                                <td>57%</td>
                            </tr>
                            <tr>
                                <td>Nome 5</td>
                                <td>120</td>
                                <td>34</td>
                                <td>28%</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </ContextGraphycFooter>
        </Container>
    )
}