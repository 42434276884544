import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';

import Modal from "react-bootstrap/Modal";

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';

import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";

import { Container } from './styles';
import { api } from '../../../services/api';

export function AddVeiculo({ handleAddVeiculo, idProprietario, ...props }) {
    const [agregador, setAgregador] = useState('');
    const [nomeAgregador, setNomeAgregador] = useState('');
    const [financeiro, setFinanceiro] = useState('');
    const [nomeFinanceiro, setNomeFinanceiro] = useState('');
    const [flag, setFlag] = useState(undefined);
    const formRef = useRef(null);
    const toast = useRef(null);

    async function handleOnBlur() {
        try {
            const response = await api.get(`/agregador?cpfcnpjagregador=${agregador}`)
            setNomeAgregador(response.data.nome)
        } catch (err) {
            setNomeAgregador('')
            setAgregador('')
            toast.current.show({
                life: 10000,
                severity: 'error',
                summary: 'Mensagem de Erro',
                detail: 'Proprietario Agregador não encontrado, favor adiciona-lo em "Incluir Proprietario"!'
            });
        }
    }

    async function handleOnBlurFinanceiro() {
        try {
            const response = await api.get(`/agregador?cpfcnpjagregador=${financeiro}`)
            setNomeFinanceiro(response.data.nome)
        } catch (err) {
            setNomeFinanceiro('')
            setFinanceiro('')
            toast.current.show({
                life: 10000,
                severity: 'error',
                summary: 'Mensagem de Erro',
                detail: 'Proprietario Agregador não encontrado, favor adiciona-lo em "Incluir Proprietario"!'
            });
        }
    }

    const handleSubmit = useCallback(async (data) => {
        try {
            const schema = Yup.object().shape({
                placa: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            const newData = {
                cappassageiros: Number(data.cappassageiros),
                anomodelo: data.anomodelo,
                carroceria: data.carroceria,
                chassi: data.chassi,
                combustivel: data.combustivel,
                cpfcnpjagregador: data.cpfcnpjagregador,
                cor: data.cor,
                especietipo: data.especietipo,
                marcamodeloversao: data.marcamodeloversao,
                cilindrada: Number(data.cilindrada),
                flag7icar: Number(flag),
                numerocompartimentostanque: Number(data.numerocompartimentostanque),
                cmt: Number(data.cmt),
                pbt: Number(data.pbt),
                tara: Number(data.tara),
                cnpjfinanceiro: data.cnpjfinanceiro,
                potenciacilindrada: Number(data.potenciacilindrada),
                placa: data.placa,
                renavam: data.renavam,
                placamercosul: data.placamercosul,
                tipoprodutotransporte: data.tipoprodutotransporte,
            }
            await handleAddVeiculo(newData, idProprietario);
            props.onHide();
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
            toast.current.show({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Erro ao criar veiculo, tente novamente!' });
        }
    }, [flag, handleAddVeiculo, idProprietario, props]);

    return (
        <>
            <Toast ref={toast} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Container>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2 style={{ textAlign: 'center' }}>Adicionar Veiculo</h2>
                        </Modal.Title>
                        {/* <AiOutlineClose onClick={() => props.onHide()} size={20} color="red" /> */}
                    </Modal.Header>

                    <Modal.Body>
                        <FormBootstrap
                            as={Form}
                            ref={formRef}
                            initialData={{ flag7icar: null }}
                            onSubmit={handleSubmit}>
                            <Row>
                                <Col style={{ maxWidth: 120 }}>
                                    <FormBootstrap.Label>Placa</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="placa"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Placa Mercosul</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="placamercosul"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Ano Modelo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="anomodelo"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Cap.Passageiros</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cappassageiros"
                                        type="number"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 300 }}>
                                    <FormBootstrap.Label>Carroceria</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="carroceria"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 150 }}>
                                    <FormBootstrap.Label>Cilindrada</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cilindrada"
                                        type="number"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col style={{ width: 400 }}>
                                    <FormBootstrap.Label>Chassi</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="chassi"
                                    />
                                </Col>

                                <Col style={{ maxWidth: 300 }}>
                                    <FormBootstrap.Label>Combustivel</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="combustivel"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Cor</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cor"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Especie-tipo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="especietipo"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Marca Modelo Versão</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="marcamodeloversao"
                                        style={{ maxWidth: 250 }}
                                    />
                                </Col>
                                <Col style={{ maxWidth: 120 }}>
                                    <FormBootstrap.Label>CMT</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cmt"
                                        type="number"
                                    />
                                </Col>

                                <Col style={{ maxWidth: 100 }}>
                                    <FormBootstrap.Label>PBT</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="pbt"
                                        type="number"
                                    />
                                </Col>
                                <Col style={{ maxWidth: 100 }}>
                                    <FormBootstrap.Label>Tara</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="tara"
                                        type="number"
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col style={{ maxWidth: 250 }}>
                                    <FormBootstrap.Label>Potencia Cilindrada</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="potenciacilindrada"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Renavam</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="renavam"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Agente Financeiro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cnpjfinanceiro"
                                        onChange={(e) => setFinanceiro(e.target.value)}
                                        value={financeiro}
                                        onBlur={handleOnBlurFinanceiro}
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Agregador CNPJ</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={InputMask}
                                        mask="99.999.999/9999-99"
                                        maskChar=""
                                        name="cpfcnpjagregador"
                                        onChange={(e) => setAgregador(e.target.value)}
                                        value={agregador}
                                        onBlur={handleOnBlur}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Nome do Financeiro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="nome_agregador"
                                        disabled
                                        onChange={(e) => setNomeFinanceiro(e.target.value)}
                                        value={nomeFinanceiro}
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Nome do Agregador</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="nome_agregador"
                                        disabled
                                        onChange={(e) => setNomeAgregador(e.target.value)}
                                        value={nomeAgregador}
                                    />
                                </Col>
                            </Row>
                            <fieldset style={{ border: '1px solid #e63946', padding: 20, marginTop: 10 }}>
                                <legend style={{ color: '#d00000', fontFamyly: 'Roboto', fontWeight: 600, textAlign: 'center' }}>Produtos Perigosos</legend>
                                <Row>
                                    <Col>
                                        <FormBootstrap.Group
                                            // as={Row}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start'
                                            }}>
                                            <FormBootstrap.Label as="legend" column sm={1} style={{ marginRight: 10 }}>
                                                Flag
                                        </FormBootstrap.Label>
                                            <FormBootstrap.Check
                                                className="mr-3"
                                                type="radio"
                                                label="7i"
                                                name="formHorizontalRadios"
                                                onChange={e => setFlag(e.target.value)}
                                                value={0}
                                                id="7i"
                                            />
                                            <FormBootstrap.Check
                                                className="mr-3"
                                                type="radio"
                                                label="Car"
                                                name="formHorizontalRadios"
                                                id="car"
                                                onChange={e => setFlag(e.target.value)}
                                                value={1}
                                            />

                                            <FormBootstrap.Check
                                                type="radio"
                                                label="Nenhum"
                                                name="formHorizontalRadios"
                                                id="nenhum"
                                                onChange={e => setFlag(e.target.value)}
                                                value={undefined}
                                                defaultChecked
                                            />
                                        </FormBootstrap.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <FormBootstrap.Label>Número Compartimentos do tanque</FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            as={Input}
                                            name="numerocompartimentostanque"
                                            type="number"
                                            style={{ maxWidth: 200 }}
                                        />
                                    </Col>
                                    <Col>
                                        <FormBootstrap.Label>Tipo Produto Transporte</FormBootstrap.Label>
                                        <FormBootstrap.Control
                                            as={Input}
                                            name="tipoprodutotransporte"
                                        />
                                    </Col>
                                </Row>
                            </fieldset>
                            <Button type="submit" className="mt-2 mb-4">Adicionar</Button>
                        </FormBootstrap>
                    </Modal.Body>
                </Container>
            </Modal>
        </>
    )
}