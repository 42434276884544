
import React from 'react';

import {
    Route as ReactDOMRoute,
    Redirect
} from 'react-router-dom';

import { UserAuth } from '../hooks/authUser';

export const Route = ({
    isPublic = false,
    isUserPrivate = false,
    component: Component,
    ...rest
}) => {
    const { user } = UserAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                return isUserPrivate === !!user ? (
                    <Component />
                ) : (
                    <Redirect
                        to={{
                            pathname: isUserPrivate ? '/' : '/dashboard/atendimento',
                            state: { from: location },
                        }}
                    />
                );
            }}
        >

        </ReactDOMRoute>
    )
}