import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

// import { MenuHome } from '../../components/MenuHome';

import { Container } from './styles';

export function EsqueciMinhaSenha() {
    const history = useHistory();
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()

        const data = {
            email: email,
        }
        console.log(data)

        history.push('/')
    }
    return (
        <Container>
            {/* <MenuHome /> */}
            <div className="content">
                <Form onSubmit={handleSubmit}>
                    <h2>Recuperar senha</h2>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="example@example.com"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <div className="buttons">
                        <Button variant="primary" type="submit">
                            Enviar
                        </Button>
                        <Form.Label as={Link} to="/">Voltar</Form.Label>
                    </div>
                </Form>
            </div>
        </Container>
    )
}