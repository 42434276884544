import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';



export function AbaMensagens() {
    return (
        <div>
            <div className="card">
                <DataTable className="p-datatable-responsive-demo" value={[{operador: "Fulano", mensagem: "Uma Mensagem", datahora: "dd/mm/aaaa", delete:<React.Fragment><Button icon="pi pi-trash" className="p-button-rounded p-button-danger"/></React.Fragment>}]}>
                    <Column field="datahora" header="Data/Hora" sortable />
                    <Column field="operador" header="Operador" sortable />
                    <Column field="mensagem" header="Mensagem" sortable />
                    <Column field="delete" header="Ações"></Column>
                </DataTable>
            </div>

            <div className="card mt-5">
                <div className="card-header">
                    Mensagem
                </div>
                <div className="card-body" >
                    <InputTextarea rols={15} cols={110} />
                </div>
                <div className="card-footer">
                    <Button>Gravar</Button>
                </div>
            </div>

        </div>
    )
}