import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import { AiOutlineClose } from 'react-icons/ai';

import Modal from "react-bootstrap/Modal";

import Input from '../../../components/Input';

import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";

import { Container } from './styles';
import { estados } from '../../../utils/states';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { AddTelefone } from './AddTelefone';
import { UpdateTelefone } from './UpdateTelefone';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { api } from '../../../services/api';

export function UpdateContato({
    editContato,
    handleUpdateContato,
    TelefonePrioritario,
    stateUpdateTelefone,
    updateTelefonePrioritario,
    handleDeleteTelefone,
    ...props
}) {
    const toast = useRef(null);
    const formRef = useRef(null);
    const [modalAddTelefone, setModalAddTelefone] = useState(false);
    const [modalUpdateTelefone, setModalUpdateTelefone] = useState(false);
    const [rowEditTel, setRowEditTel] = useState('');
    const [telefones, setTelefones] = useState([]);

    useEffect(() => {
        setTelefones(editContato.telefones_contato)
    }, [editContato.id_contato, editContato.telefones, editContato.telefones_contato]);

    async function handleAddTelefone(telefoneObj) {
        try {
            const teste = telefones.filter(mapped => mapped.prioritario !== 0)

            let t = telefoneObj
            if (teste.length === 1) {
                t = {
                    ...telefoneObj,
                    prioritario: 0
                }
            }
            const responseTel = await api.post('/telefone', {
                telefone: t,
                contato_id: editContato.id_contato,
                proprietario_id: editContato.proprietario_id
            })
            const data = responseTel.data;

            TelefonePrioritario(data)
            // setTelefones([...telefones, data])
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel criar o telefone, tente novamente' });
        }
    }

    async function handleUpdateTelefone(dataObj) {
        try {
            const newDataOb = {
                ...rowEditTel,
                ...dataObj
            }
            await api.put(`/telefone?id_telefone=${rowEditTel.id_telefone}`, {
                telefone: newDataOb,
                contato_id: editContato.id_contato,
                proprietario_id: editContato.proprietario_id
            })
            const newData = {
                ...rowEditTel,
                ...dataObj
            }
            // setTelefones(
            //     old => old.map(mapped => mapped.id_telefone === rowEditTel.id_telefone ? newData : mapped)
            // )

            stateUpdateTelefone(newData);
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel atualizar o telefone, tente novamente!' });
        }
    }

    async function handleDeleteRow(rowDataObjectDelete) {
        try {
            await api.delete(`/telefone?id_telefone=${rowDataObjectDelete.id_telefone}`)
            handleDeleteTelefone(editContato, rowDataObjectDelete)
            setTelefones(mapped => mapped.filter(filt => filt.id_telefone !== rowDataObjectDelete.id_telefone))
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel excluir o telefone' });
        }
    }

    function handleModalEditRow(rowDataObjectEdit) {
        setRowEditTel(rowDataObjectEdit);
        setModalUpdateTelefone(true);
    }

    const handleSubmit = useCallback(async (data) => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            await handleUpdateContato(data);
            toast.current.show({ severity: 'success', summary: 'Mensagem de Successo', detail: 'Contato atualizado com Sucesso' });
            props.onHide()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
            toast.current.show({ severity: 'erro', summary: 'Mensagem de Erro', detail: 'Falha ao atualizar, tente novamente!' });
        }
    }, [handleUpdateContato, props]);

    const actionContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex' }}>
                    <span className="p-column-title">
                        <BiTrash size={25} onClick={() => handleDeleteRow(rowData)} />
                    </span>
                    <span className="p-column-title">
                        <BiEdit size={25} onClick={() => handleModalEditRow(rowData)} />
                    </span>
                </div>
            </React.Fragment>
        );
    }

    async function handleTrue(rowData) {
        const newDataTelefone2 = telefones.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0
        }))
        await api.patch(`/telefone`, newDataTelefone2)
        updateTelefonePrioritario(newDataTelefone2, editContato)

        setTelefones(old => old.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0,
        })))
    }

    const actionPrioritario = (rowData) => {
        return (
            <React.Fragment>

                {rowData.prioritario === 0 ? (
                    <span onClick={() => handleTrue(rowData)}>
                        <ImCheckboxUnchecked />
                    </span>
                ) : (
                    <span>
                        <ImCheckboxChecked color="green" />
                    </span>
                )}
            </React.Fragment>
        )
    }

    const showDDDNumber = (rowData) => {
        const formatDDD = rowData.ddd.replace(/(\d{1})(\d{1})/, "($1$2)")
        return (
            <React.Fragment>
                {formatDDD}
            </React.Fragment >
        )
    }

    const showNumberPhone = (rowData) => {
        const formatNumberPhone = rowData.numerotel.replace(/(\d{5})(\d{4})/, "$1-$2")
        return (
            <React.Fragment>
                {formatNumberPhone}
            </React.Fragment >
        )
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Toast ref={toast} />
            <Container>
                <AddTelefone
                    show={modalAddTelefone}
                    onHide={() => setModalAddTelefone(false)}
                    handleAddTelefone={handleAddTelefone}
                />

                <UpdateTelefone
                    show={modalUpdateTelefone}
                    onHide={() => setModalUpdateTelefone(false)}
                    rowEditTel={rowEditTel}
                    handleUpdateTelefone={handleUpdateTelefone}
                />

                <Modal.Header>
                    <Modal.Title>
                        <h2 style={{ textAlign: 'center' }}>Editar Contato</h2>
                    </Modal.Title>
                    <AiOutlineClose onClick={() => props.onHide()} size={20} color="red" />
                </Modal.Header>

                <Modal.Body>
                    <FormBootstrap
                        as={Form}
                        ref={formRef}
                        onSubmit={handleSubmit}
                        initialData={editContato}
                    >
                        <Row>
                            <Col>
                                <FormBootstrap.Label>Nome</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="nome"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <FormBootstrap.Label>Endereço</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="endereco"
                                />
                            </Col>
                        </Row>

                        <Row className="mt-2">
                            <Col>
                                <FormBootstrap.Label>Número</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="numero"
                                />
                            </Col>
                            <Col>
                                <FormBootstrap.Label>Complemento</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="complemento"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <FormBootstrap.Label>Bairro</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="bairro"
                                />
                            </Col>
                            <Col style={{ maxWidth: '200px' }}>
                                <FormBootstrap.Label>Cidade</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="cidade"
                                />
                            </Col>
                            <Col style={{ maxWidth: '100px' }}>
                                <FormBootstrap.Label>UF</FormBootstrap.Label>
                                <FormBootstrap.Control as="select" defaultValue="escolha..">
                                    {estados.map((estado) => (
                                        <option key={estado.value}>{estado.label}</option>
                                    ))}
                                </FormBootstrap.Control>
                            </Col>
                            <Col style={{ maxWidth: '150px' }}>
                                <FormBootstrap.Label>CEP</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="cep"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <FormBootstrap.Label>E-mail</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="email"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <FormBootstrap.Label>Data de Nascimento</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    type="date"
                                    as={Input}
                                    name="data_nasc"
                                />
                            </Col>
                            <Col>
                                <FormBootstrap.Label>Tipo de Contato</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="tipo_contato"
                                />
                            </Col>
                            <Col>
                                <FormBootstrap.Label>Cargo</FormBootstrap.Label>
                                <FormBootstrap.Control
                                    as={Input}
                                    name="cargo"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    className="mb-2 mt-4"
                                    type="submit"
                                >Atualizar</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-2 mt-4"  >
                                <div className="adcTelefone">
                                    <Button onClick={() => setModalAddTelefone(true)}>Adicionar Telefone</Button>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-4">
                            <Col style={{ maxWidth: 700 }}>
                                <div className="datatable-rowexpansion-demo">
                                    <div className="card">
                                        <DataTable
                                            paginator
                                            scrollable scrollHeight="calc(100vh - 300px)"
                                            rows={5}
                                            value={telefones}
                                        >
                                            <Column field="ddd" header="DDD" style={{ width: 70 }} body={showDDDNumber} />
                                            <Column field="numerotel" header="Número" body={showNumberPhone} />
                                            <Column field="tipo_telefone" header="Tipo" style={{ width: 80 }} />
                                            <Column field="prioritario" header="Prioritario" body={actionPrioritario} />
                                            <Column
                                                field="action"
                                                header="Ações"
                                                body={actionContatoBodyTemplate}
                                                style={{ width: '80px' }} />
                                        </DataTable>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </FormBootstrap>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>
            </Container>
        </Modal >
    )
}