import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 32px;
    

    form {
        
        height: 700px;

        @media (max-width: 1000px) {
            height: 500px;
        }
        /* a {
            color: #fff;
            border-top-left-radius: 200px;
            border-top-right-radius: 200px;
            background: #03071E;
        } */

        .hPAwwI {
            overflow-x: scroll;
            border: 1px solid red;
        }
    }

    .buttonAtendimento {
        margin-top: 10px;
        justify-content: space-around;
        display: flex;
        align-items: center;
        /* margin: 0 auto; */

        button {
            width: 120px;
        }
    }

    .calendarDateAjust {
        height: 500px;
        width: 600px;
        /* max-width: 600px; */
        margin: 0 auto;
    }

    h1 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.4rem;
        text-align: center;
    }

    .buttonMensagem {
        text-align: right;
    }

    /* button {
        border: none;
        height: 40px;
        width: 100px;
        margin-top: 20px;
        border-radius: 10px;
        color: #fff;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);

        background: #99d98c;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8);
        }
    } */
    svg {
        position: absolute;
        right: 0;
        top: 0;

        transition: filter 0.2s;

        &:hover {
            cursor: pointer;
            filter: brightness(0.8);
        }
    }

    .inputSmallRow {
        max-width: 160px;
    }

    .rowUserAtendimento {
        max-width: 460px;
    }

    .inputDate {
        max-width: 200px;
    }

    .inputSmallRow2 {
        max-width: 500px;
    }

    .ajustUf {
        max-width: 120px;
    }

    .inputWidthCep {
        max-width: 250px;
    }

    .rowData {
        width: 180px;
    }

    .rowLogin {
        width: 100px;
    }

    .rowUser {
        width: 150px;
    }

    .datatable-rowexpansion-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .datatable-rowexpansion-demo .orders-subtable {
        padding: 1rem;
    }
`;

export const ContainerTableContatos = styled.div`
    .card {
        max-width: 1000px;
        margin: 0 auto;
    }
`;