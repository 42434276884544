import React, { useCallback, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import { UserAuth } from '../../hooks/authUser';

import Logo from '../../assets/logo.JPG';
import { Container } from './styles';
import { Toast } from 'primereact/toast';
// import Input from '../../components/Input';

export function Logon() {
    const formRef = useRef(null);
    const toast = useRef(null);
    const { signIn } = UserAuth();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');

    const handleSubmit = useCallback(async (e) => {
        try {
            e.preventDefault()

            const data = {
                email,
                senha
            }

            await signIn({
                email: data.email,
                senha: data.senha,
            })

            history.push('/dashboard/atendimento');

        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Falha ao fazer login, tente novamente!' });
        }
    }, [email, history, senha, signIn]);
    return (
        <Container>
            <Toast ref={toast} />
            {/* <MenuHome /> */}
            <div className="content">
                <div className="logo"><img src={Logo} alt="Logo" /></div>

                {/* <FormInput onSubmit={handleSubmit}>
                    <Input name="email" />
                    <Input name="senha" />
                    <button type="submit">Entrar</button>
                </FormInput> */}
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="example@example.com"
                            onChange={e => setEmail(e.target.value)}
                        />
                        <Form.Text className="text-muted">
                            Nunca compartilharemos seu e-mail com mais ninguém.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Senha"
                            name="senha"
                            onChange={e => setSenha(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="groupLabel" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Lembrar-me" />
                        <Form.Label as={Link} to="/esqueci-minha-senha">Esqueci minha senha</Form.Label>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Entrar
                    </Button>
                </Form>
            </div>
        </Container>
    )
}