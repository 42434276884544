import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

function Modal({ children, isOpen, setIsOpen }) {
    const [modalStatus, setModalStatus] = useState(isOpen)

    useEffect(() => {
        setModalStatus(isOpen);
    }, [isOpen]);
    
  return (
      <ReactModal
        shouldCloseOnOverlayClick={false}
        onRequestClose={setIsOpen}
        isOpen={modalStatus}
        ariaHideApp={false}
        style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                background: '#fff',
                color: '#000000',
                borderRadius: '8px',
                boxShadow: '1px 1px 8px rgba(0, 0, 0, 0.4)',
                border: 'none',
              },
              overlay: {
                backgroundColor:'rgba(253,255,255,0.1)',
                backdropFilter: 'blur(3px)',
                zIndex: '100',
              },
        }}
      >
          {children}
      </ReactModal>
  );
}

export default Modal;