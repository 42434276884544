import React from 'react';

import { UserAuthProvider } from './authUser';

export function AppProvider({ children }) {
    return (
        <UserAuthProvider>
            {children}
        </UserAuthProvider>
    )
}