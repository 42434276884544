import styled from 'styled-components';

export const Container = styled.div`

    input {
        margin-left: 5px;
        
    }
    label {
        margin-right: 10px;
        
        &:hover {
            cursor: pointer;
        }
    }

    span {
        font-weight: 400;
        margin-left: 5px;
        font-family: 'Roboto';

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.9);
            
        }
    }
`;