import React from "react";
import CanvasJSReact from "./assets/canvasjs.react";
var Component = React.Component;
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


export default class FunnelGrap extends Component {
    render() {
        var dataPoint;
        var total;

        const options = {
            animationEnabled: true,
            theme: "",

            title: {
                text: "Funil de Vendas",
                // fontColor: "#000"
            },
            data: [
                {
                    type: "funnel",
                    toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
                    indexLabelPlacement: "inside",
                    indexLabel: "{label} ({percentage}%)",
                    dataPoints: [
                        { y: 578, label: "Lead" },
                        { y: 1212, label: "Aguardando Contato" },
                        { y: 1080, label: "Atendimento Espera" },
                        { y: 665, label: "Pendente" },
                        { y: 578, label: "Não Fechou" },
                        { y: 176, label: "Envio Cotação" },
                        { y: 578, label: "Agendou Inspeção" },
                        { y: 578, label: "Realizou Inspeção" },
                        { y: 578, label: "Não Conformidade Inspeção" },
                        { y: 578, label: "Pós Atendimento" }
                    ]
                }
            ]
        };
        //calculate percentage
        dataPoint = options.data[0].dataPoints;
        total = dataPoint[0].y;
        for (var i = 0; i < dataPoint.length; i++) {
            if (i === 0) {
                options.data[0].dataPoints[i].percentage = 100;
            } else {
                options.data[0].dataPoints[i].percentage = (
                    (dataPoint[i].y / total) *
                    100
                ).toFixed(2);
            }
        }
        return (
            <div>
                <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
            </div>
        );
    }
}
// module.exports = FunnelGrap;
