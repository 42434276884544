import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export function AbaLogs() {
    return (
        <div className="card">
            <DataTable className="p-datatable-responsive-demo" value={[{operador: "Outro Fulano", log: "Um Log", datahora: "dd/mm/aaaa"}]}>
                <Column field="datahora" header="Data/Hora" sortable />
                <Column field="operador" header="Operador" sortable />
                <Column field="log" header="Mensagem" sortable />
            </DataTable>
        </div>
    )
}