import React, { useRef, useState } from 'react';
import { Button, Col, Form as Formbootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import { v4 as uuidv4 } from "uuid";
import { AiOutlineClose } from 'react-icons/ai';

import getValidationErrors from "../../../../utils/getValidationErrors";
import * as Yup from "yup";

// import Modal from '../../../../components/Modal';
import Modal from "react-bootstrap/Modal";

import SelectUnform from '../../../../components/SelectUnform';
import InputMask from '../../../../components/InputMask';

import { Container } from './styles';

export function AddTelefone({
    handleAddTelefone,
    ...props
}) {
    const toast = useRef(null);
    const formRef = useRef(null);
    const [prioritario, setPrioritario] = useState(1);

    const optionsTipo = [
        { value: "movel", label: "Movel" },
        { value: "fixo", label: "Fixo" }
    ]

    async function handleSubmitAddContato(data) {
        try {
            const validDDD = data.ddd;
            const validNumero = data.numerotel;
            const newDDD = validDDD.replace(/\.|-|\/|\\|\(|\)/g, '');
            const newNumero = validNumero.replace(/\.|-|\/|\\|\(|\)/g, '');

            const schema = Yup.object().shape({
                ddd: Yup.string().required("obrigátorio"),
                numerotel: Yup.string().required("obrigátorio")
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            const newDataTelefone = {
                ...data,
                ddd: newDDD,
                numerotel: newNumero,
                id_telefone: uuidv4(),
                prioritario
            }

            setPrioritario(0);

            await handleAddTelefone(newDataTelefone)
            props.onHide()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
        }

    }

    return (
        <>
            <Toast ref={toast} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Container>
                    <Modal.Header className="mb-2">
                        <Modal.Title id="contained-modal-title-vcenter">
                            Adicionar Telefone
                        </Modal.Title>
                        <AiOutlineClose onClick={() => props.onHide()} size={20} color="red" />
                    </Modal.Header>

                    <Form ref={formRef} onSubmit={handleSubmitAddContato}>
                        <Row>
                            <Col>
                                <fieldset>
                                    <div
                                        style={{ display: "flex" }}
                                        className="schedule-item mb-2"
                                    >
                                        <Formbootstrap.Control
                                            style={{ width: 75 }}
                                            className="mr-2"
                                            as={InputMask}
                                            name="ddd"
                                            mask="(99)"
                                            maskChar=""
                                            placeholder="DDD"
                                        />
                                        <Formbootstrap.Control
                                            as={InputMask}
                                            mask="99999-9999"
                                            maskChar=""
                                            name="numerotel"
                                            placeholder="Número telefone"
                                        />
                                        <SelectUnform
                                            name="tipo_telefone"
                                            options={optionsTipo}
                                            className="ml-2"
                                        // value={select}
                                        // onChange={(e) => setSelect(e.target.value)}
                                        >
                                            {optionsTipo.map(tipo => (
                                                <option key={tipo.value}>{tipo.label}</option>
                                            ))}
                                        </SelectUnform>
                                    </div>
                                </fieldset>
                            </Col>
                        </Row>
                        <Button type="submit" className="mt-2">Adicionar</Button>
                    </Form>
                </Container>
            </Modal>
        </>
    )
}