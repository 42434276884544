import styled from 'styled-components';

export const Container = styled.div`
    /* position: relative; */
    padding: 32px;
    h2 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.8rem;
    }

    .button {
        display: flex;
        justify-content: center;

        button {
            height: 40px;
            border: none;
            width: 100%;

            font-family: Roboto;

            background: #99d98c;

            transition: filter 0.2s;

            &:hover {
                filter: brightness(0.9);
            }
        }
    }

    svg {
        /* position: absolute; */
        right: 0;
        top: 0;

        transition: filter 0.2s;

        &:hover {
            cursor: pointer;
            filter: brightness(0.8);
        }
    }

    form {

.btnSubmit {
    padding: 10px 0;
    display: flex;
    align-items: center;

    h4 {
        font-family: 'Roboto';
        font-size: 0.9rem;
        span {
            font-weight: 600;
            font-size: 1rem;
            margin: 10px;
            font-family: 'Roboto';
            color: #e63946;
        }
    }

    .errMoreTrue {
        border: 1px solid red;
    }
}

.btnDisabled {
    &:hover {
        cursor: not-allowed;
    }
}

.adcTelefone {
    display: flex;
    align-items: center;

    h4 {
        font-family: 'Roboto';
        font-size: 0.8rem;
        font-weight: 500;
        
        span {
            font-family: 'Roboto';
            font-weight: 600;
            font-size: 1rem;
            color: #e63946;
            text-transform: uppercase;
            margin: 0 10px;
        }
    }
}
}
`;