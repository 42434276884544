import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

export function AbaAtendimento() {

    const [status, setStatus] = useState(null)
    const setStatusHandler = (e) => {
        setStatus(status = e.value) 
    }
    const statusOptions = [
        { name: 'Opção 1'},
        { name: 'Opção 2'},
        { name: 'Opção 3'},
        { name: 'Opção 4'},
        { name: 'Opção 5' }]

    return (
        <div className="p-grid">
            <div className="p-col">
                <div>Usuário</div>
                <InputText value={'Usuário'} disabled />
            </div>
            <div className="p-col">
                <div>Data Abertura</div>
                <InputText value={'dd/mm/aaaa'} disabled />
            </div>
            <div className="p-col">
                <div>Data Fechamento</div>
                <InputText value={'dd/mm/aaaa'} disabled />
            </div>
            <div className="p-col">
                <div>Data Expiração</div>
                <InputText value={'dd/mm/aaaa'} disabled />
            </div>
            <div className="p-col">
                <div>Data Agendamento</div>
                <InputText value={'dd/mm/aaaa'} disabled />
            </div>
            <div className="p-col">
                <div>Status</div>
                <Dropdown value={status} options={statusOptions} onChange={setStatusHandler} optionLabel="name" placeholder="Status" />
            </div>
            <div className="p-col">
                <div>Escopo</div>
                <InputText value={'Escopo'} disabled />
            </div>
            <div className="p-col">
                <div>Motivo de perda!</div>
                <Dropdown value={status} options={statusOptions} onChange={setStatusHandler} optionLabel="name" placeholder="Motivo" />
            </div>
            <div className="p-col">
                <div>Valor</div>
                <InputText value={'Valor'} />
            </div>
            <div className="p-col">
                <div>Valor Referência</div>
                <InputText value={'Valor Referência'} disabled />
            </div>
            <div className="p-col">
                <div>Origem Leady</div>
                <Dropdown value={status} options={statusOptions} onChange={setStatusHandler} optionLabel="name" placeholder="Origem" />
            </div>
            <div className="p-col">
                <div>Campanha</div>
                <InputText value={'Campanha'} disabled />
            </div>
            <div className="p-col">
                <div>Forma de Pagamento</div>
                <Dropdown value={status} options={statusOptions} onChange={setStatusHandler} optionLabel="name" placeholder="Pagamento" />
            </div>
        </div>
    )
}