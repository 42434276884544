import React, { useRef, useEffect, useState, useCallback } from "react";
import { useField } from "@unform/core";
// import { FiAlertCircle } from 'react-icons/fi';

import { Form } from "react-bootstrap";

import {
    Container,
    // Error
} from './styles';

const Select = ({ name, options, icon: Icon, ...rest }) => {
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [isFilled, SetIsFilled] = useState(false);

    const selectRef = useRef(null);

    const handleInputBlur = useCallback(() => {
        SetIsFilled(!!selectRef.current?.value);
    }, []);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: selectRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container
            isErrored={!!error}
            isFilled={isFilled}
        >
            <Form.Control
                onBlur={handleInputBlur}
                as="select"
                ref={selectRef}
                defaultValue={defaultValue}
                options={options}
                {...rest}
            />
        </Container>
    );
};

export default Select;
