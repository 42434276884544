import { Switch } from 'react-router-dom';

import { Route } from './routes'

import { Logon } from '../pages/Logon';
import { EsqueciMinhaSenha } from '../pages/EsqueciMinhaSenha';

import { Campanha } from '../pages/Campanha';
// import { UploadCSV } from '../pages/UploadCSV';
// import DemoApp from '../pages/TesteCalendar';
// import { DataTableRowExpansionDemo } from '../pages/DashboardTeste/teste.jsx';
import { BuscaProprietario } from '../pages/BuscaProprietario';
import { Dashboard } from '../pages/Dashboard';
import { AtualizarDados } from '../pages/AtualizarDados';

export function Routes() {
    return (
        <Switch>
            <Route path="/" component={Logon} exact />
            <Route path="/esqueci-minha-senha" component={EsqueciMinhaSenha} />

            <Route path="/dashboard" exact component={Dashboard} isUserPrivate />
            <Route path="/dashboard/atendimento" exact component={BuscaProprietario} isUserPrivate />
            {/* <Route path="/teste" exact component={DataTableRowExpansionDemo} isUserPrivate /> */}
            <Route path="/campanha" component={Campanha} isUserPrivate />
            <Route path="/atualizardados" exact component={AtualizarDados} isUserPrivate />
            {/* <Route path="/uploads" component={UploadCSV} isUserPrivate /> */}
            {/* <Route path="/calendar" component={DemoApp} isUserPrivate /> */}
        </Switch>
    )
}