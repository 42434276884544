import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";

import { Container } from './styles';
import { Col, Form, Row, Button, Table } from 'react-bootstrap';
import { estados } from '../../../utils/states';

import { PickListSelect } from '../../../components/PickList';


export function ModalCampanha({ ...props }) {
    const [buttonType, setButtonType] = useState(false);

    const escopos = [
        { id: 1, label: "escopo1" },
        { id: 2, label: "escopo2" }
    ]

    const veiculoPlaca = [
        { id: 1, label: "0" },
        { id: 2, label: "1" },
        { id: 3, label: "2" },
        { id: 4, label: "3" },
        { id: 5, label: "4" },
        { id: 6, label: "5" },
        { id: 7, label: "6" },
        { id: 8, label: "7" },
        { id: 9, label: "8" },
        { id: 10, label: "9" },
    ]

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Container>
                <Modal.Header closeButton>
                    <Modal.Title>Nome da campanha</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form className="mt-3">
                        {buttonType === false ? (
                            <>
                                <Row>
                                    <Col>
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Escopo</Form.Label>
                                        <Form.Control as="select" defaultValue="escolha..">
                                            {escopos.map((escopo) => (
                                                <option key={escopo.id}>{escopo.label}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Data Inicio</Form.Label>
                                        <Form.Control type="date" />
                                    </Col>
                                    <Col>
                                        <Form.Label>Data Fim</Form.Label>
                                        <Form.Control type="date" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Local Templates SMS</Form.Label>
                                        <Form.Control type="file" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Local Template do Script </Form.Label>
                                        <Form.Control type="file" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Local Template do WhatsApp </Form.Label>
                                        <Form.Control type="file" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Local Template do E-mail Marketing </Form.Label>
                                        <Form.Control type="file" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Control as="select" defaultValue="escolha..">
                                            {escopos.map((escopo) => (
                                                <option key={escopo.id}>{escopo.label}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <div>
                                    <h1>Adição do Filtro</h1>
                                    <Row>
                                        <Col>
                                            <Table bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Laudo</th>
                                                        <th>Inicio</th>
                                                        <th>Fim</th>
                                                        <th>Todos</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Emissão</td>
                                                        <td>
                                                            <Col style={{ maxWidth: 220 }}>
                                                                <Form.Control type="date" />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col style={{ maxWidth: 220 }}>
                                                                <Form.Control type="date" />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col>
                                                                <Form.Control type="radio" />
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Vencimento</td>
                                                        <td>
                                                            <Col style={{ maxWidth: 220 }}>
                                                                <Form.Control type="date" />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col style={{ maxWidth: 220 }}>
                                                                <Form.Control type="date" />
                                                            </Col>
                                                        </td>
                                                        <td>
                                                            <Col>
                                                                <Form.Control type="radio" />
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="1">Todos em aberto até</td>
                                                        <td>
                                                            <Col style={{ maxWidth: 220 }}>
                                                                <Form.Control type="date" />
                                                            </Col>
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            <Col>
                                                                <Form.Control type="radio" />
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Status Laudo</td>
                                                        <td>
                                                            <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                {/* <Col sm={10}> */}
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Conforme"
                                                                    name="formHorizontalRadios"
                                                                    id="formHorizontalRadios1"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Não Conforme"
                                                                    name="formHorizontalRadios"
                                                                    id="formHorizontalRadios2"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Todos"
                                                                    name="formHorizontalRadios"
                                                                    id="formHorizontalRadios3"
                                                                />
                                                                {/* </Col> */}
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Local de Inspeção</td>
                                                        <td>
                                                            <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                {/* <Col sm={10}> */}
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Na Empresa"
                                                                    name="formHorizontalRadios"
                                                                    id="formHorizontalRadios1"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    label="Todos"
                                                                    name="formHorizontalRadios"
                                                                    id="formHorizontalRadios3"
                                                                />
                                                                {/* </Col> */}
                                                            </Form.Group>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Filtro proprietario</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Com Nome</td>
                                                        <td>
                                                            <Col style={{ height: 30 }}>
                                                                <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Sim"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Não"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Todos"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios3"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Com Telefone Fixo</td>
                                                        <td>
                                                            <Col style={{ height: 30 }}>
                                                                <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Sim"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Não"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Todos"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios3"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Com Telefone Movel</td>
                                                        <td>
                                                            <Col style={{ height: 30 }}>
                                                                <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Sim"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Não"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Todos"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios3"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Com Email</td>
                                                        <td>
                                                            <Col style={{ height: 30 }}>
                                                                <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Sim"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Não"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Todos"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios3"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Com Endereço</td>
                                                        <td>
                                                            <Col style={{ height: 30 }}>
                                                                <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Sim"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Não"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios1"
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Todos"
                                                                        name="formHorizontalRadios"
                                                                        id="formHorizontalRadios3"
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col style={{ maxWidth: '100px' }}>
                                            <Form.Label>UF</Form.Label>
                                            <Form.Control as="select" defaultValue="escolha..">
                                                {estados.map((estado) => (
                                                    <option key={estado.value}>{estado.label}</option>
                                                ))}
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PickListSelect />
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col>
                                            <Form.Group as={Row} style={{ alignItems: 'center', gap: '20px' }}>
                                                <Form.Label as="legend" column sm={3}>
                                                    Bairro
                                        </Form.Label>
                                                <Form.Check
                                                    type="radio"
                                                    label="Todos"
                                                    name="formHorizontalRadios"
                                                    id="formHorizontalRadios1"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <PickListSelect />
                                        </Col>
                                    </Row>
                                    <Row className="mt-4" >
                                        <Col style={{ maxWidth: '180px' }}>
                                            <Form.Label>Veiculo Final Placa</Form.Label>
                                            <Form.Control style={{ maxWidth: '60px' }} as="select" defaultValue="escolha..">
                                                {veiculoPlaca.map((placa) => (
                                                    <option key={placa.value}>{placa.label}</option>
                                                ))}
                                            </Form.Control>
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                type="radio"
                                                label="Todos"
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                        {buttonType === false ? (
                            <Button className="mt-2" onClick={() => setButtonType(true)}>Adicionar</Button>
                        ) : (
                            <>
                                <Button className="mt-2 mr-2" onClick={() => { }}>Adição do Filtro</Button>
                                <Button className="mt-2 green" onClick={() => { }}>Gerar Lista de Veiculos</Button>
                            </>
                        )}
                    </Form>
                </Modal.Body>
            </Container>
        </Modal>
    )
}