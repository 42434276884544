import React, { useCallback, useRef, useState } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import { AiOutlineClose } from 'react-icons/ai';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import Modal from "react-bootstrap/Modal";

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import SelectUnform from '../../../components/SelectUnform';
import DatePicker from '../../../components/DatePicker';

import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";

import { Container } from './styles';
import { estados } from '../../../utils/states';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { AddTelefone } from './AddTelefone';
import { UpdateTelefone } from './UpdateTelefone';
import { BiTrash } from 'react-icons/bi';
import { api } from '../../../services/api';

export function AddContato({
    isOpen,
    setIsOpen,
    handleAddContato,
    idProprietario,
    handleUpdateContato,
    TelefonePrioritario,
    stateUpdateTelefone,
    updateTelefonePrioritario,
    handleDeleteTelefone,
    ...props
}) {
    const [telefones, setTelefones] = useState([]);
    const [idContato, setIdContato] = useState('');
    const [active, setActive] = useState(false)

    const [modalAddTelefone, setModalAddTelefone] = useState(false);
    const [modalUpdateTelefone, setModalUpdateTelefone] = useState(false);
    // const [rowEditTel, setRowEditTel] = useState('');
    const toast = useRef(null);
    const formRef = useRef(null);

    const handleCep = async () => {
        try {
            const cepValue = formRef.current.getFieldValue("cep");
            const cep = cepValue.replace(/\.|-|\/|\\|\(|\)/g, '');

            const response = await api.get(`/cep?cep=${cep}`)

            const cepData = response.data

            formRef.current.setFieldValue("endereco", cepData.logradouro);
            formRef.current.setFieldValue("bairro", cepData.bairro);
            formRef.current.setFieldValue("complemento", cepData.complemento);
            formRef.current.setFieldValue("cidade", cepData.localidade);
            formRef.current.setFieldValue("uf", cepData.uf);
        } catch (err) {
            console.clear();
        }
    }

    // function handleModalEditRow(rowDataObjectEdit) {
    //     setRowEditTel(rowDataObjectEdit);
    //     setModalUpdateTelefone(true);
    // }

    const handleSubmit = useCallback(async (data) => {
        try {
            const schema = Yup.object().shape({
                nome: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            let timestamp = data.datanascimento
            if (data.datanascimento !== null) {
                timestamp.toISOString()
            }

            const objectContato = {
                ...data,
                datanascimento: timestamp
            }

            const response = await api.post('/contato', {
                ...objectContato,
                proprietario_id: idProprietario
            })

            const newData = {
                ...response.data,
                telefones_contato: []
            };
            // await handleAddContato(data, newData, idProprietario);
            await handleAddContato(newData);
            setIdContato(response.data.id_contato)
            setActive(true)
            toast.current.show({ severity: 'success', summary: 'Mensagem de Sucesso', detail: 'Contato criado com sucesso!' });
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
            toast.current.show({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Falha ao cadastrar, tente novamente!' });
        }
    }, [handleAddContato, idProprietario]);

    async function handleDeleteRow(rowDataObjectDelete) {
        try {
            const objIdContato = {
                id_contato: idContato
            }
            await api.delete(`/telefone?id_telefone=${rowDataObjectDelete.id_telefone}`)
            handleDeleteTelefone(objIdContato, rowDataObjectDelete)
            setTelefones(mapped => mapped.filter(filt => filt.id_telefone !== rowDataObjectDelete.id_telefone))
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel excluir o telefone' });
        }
    }

    async function handleTrue(rowData) {
        const newDataTelefone2 = telefones.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0
        }))
        await api.patch(`/telefone`, newDataTelefone2)
        const objIdContato = {
            id_contato: idContato
        }
        updateTelefonePrioritario(newDataTelefone2, objIdContato)

        setTelefones(old => old.map(mapped => ({
            ...mapped,
            prioritario: mapped.id_telefone === rowData.id_telefone ? 1 : 0,
        })))
    }

    const showDDDNumber = (rowData) => {
        const formatDDD = rowData.ddd.replace(/(\d{1})(\d{1})/, "($1$2)")
        return (
            <React.Fragment>
                {formatDDD}
            </React.Fragment >
        )
    }

    const showNumberPhone = (rowData) => {
        const formatNumberPhone = rowData.numerotel.replace(/(\d{5})(\d{4})/, "$1-$2")
        return (
            <React.Fragment>
                {formatNumberPhone}
            </React.Fragment >
        )
    }

    const actionContatoBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div style={{ display: 'flex' }}>
                    <span className="p-column-title">
                        <BiTrash size={25} onClick={() => handleDeleteRow(rowData)} />
                    </span>
                    {/* <span className="p-column-title">
                        <BiEdit size={25} onClick={() => handleModalEditRow(rowData)} />
                    </span> */}
                </div>
            </React.Fragment>
        );
    }

    const actionPrioritario = (rowData) => {
        return (
            <React.Fragment>
                {rowData.prioritario === 0 ? (
                    <span onClick={() => handleTrue(rowData)}>
                        <ImCheckboxUnchecked />
                    </span>
                ) : (
                    <span>
                        <ImCheckboxChecked color="green" />
                    </span>
                )}
            </React.Fragment>
        )
    }

    function handleCloseModal() {
        setActive(false)
        setIdContato('')
        setTelefones([])
        props.onHide()
    }

    async function handleAddTelefone(telefoneObj) {
        try {
            const teste = telefones.filter(mapped => mapped.prioritario !== 0)

            let t = telefoneObj
            if (teste.length === 1) {
                t = {
                    ...telefoneObj,
                    prioritario: 0
                }
            }
            const responseTel = await api.post('/telefone', {
                telefone: t,
                contato_id: idContato,
                proprietario_id: idProprietario
            })
            const data = responseTel.data;

            TelefonePrioritario(data)
            setTelefones([...telefones, data])
        } catch (err) {
            toast.current.show({ severity: 'error', summary: 'Mensagem de erro', detail: 'Não foi possivel criar o telefone, tente novamente' });
        }
    }


    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Toast ref={toast} />
                <AddTelefone
                    show={modalAddTelefone}
                    onHide={() => setModalAddTelefone(false)}
                    handleAddTelefone={handleAddTelefone}
                />

                <UpdateTelefone
                    show={modalUpdateTelefone}
                    onHide={() => setModalUpdateTelefone(false)}
                // rowEditTel={rowEditTel}
                // handleUpdateTelefone={handleUpdateTelefone}
                />
                <Container>
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Adicionar Contato
                        </Modal.Title>
                        <AiOutlineClose onClick={handleCloseModal} size={20} color="red" />
                    </Modal.Header>

                    <Modal.Body>
                        <FormBootstrap as={Form} ref={formRef} onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <FormBootstrap.Label>Nome</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="nome"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Endereço</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="endereco"
                                    />
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Número</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="numero"
                                    />
                                </Col>
                                <Col>
                                    <FormBootstrap.Label>Complemento</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="complemento"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Bairro</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="bairro"
                                    />
                                </Col>
                                <Col style={{ maxWidth: '200px' }}>
                                    <FormBootstrap.Label>Cidade</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cidade"
                                    />
                                </Col>
                                <Col style={{ maxWidth: '100px' }}>
                                    <FormBootstrap.Label>UF</FormBootstrap.Label>
                                    <SelectUnform name="uf" as="select" defaultValue="escolha..">
                                        {estados.map((estado) => (
                                            <option key={estado.value}>{estado.label}</option>
                                        ))}
                                    </SelectUnform>
                                </Col>
                                <Col style={{ maxWidth: '150px' }}>
                                    <FormBootstrap.Label>CEP</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={InputMask}
                                        name="cep"
                                        onBlur={handleCep}
                                        mask="99999-999"
                                        maskChar=""
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>E-mail</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="email"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <FormBootstrap.Label>Data de Nascimento</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={DatePicker}
                                        name="datanascimento"
                                    />
                                </Col>
                                {/* <Col>
                                    <FormBootstrap.Label>Tipo de Contato</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="tipo_contato"
                                    />
                                </Col> */}
                                <Col>
                                    <FormBootstrap.Label>Cargo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="cargo"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-2 mb-2">
                                    {active === true ? (
                                        <Button
                                            type="submit"
                                            disabled
                                        >Adicionar Contato</Button>
                                    ) : (
                                        <Button
                                            type="submit"

                                        >Adicionar Contato</Button>
                                    )}
                                </Col>
                            </Row>
                        </FormBootstrap>
                        <Modal.Footer>
                            {active === true && (
                                <>
                                    <Row>
                                        <Col className="mt-4 mb-2">
                                            <Button
                                                onClick={() => setModalAddTelefone(true)}
                                            >Adicionar Telefone</Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col style={{ maxWidth: 700 }}>
                                            <div className="datatable-rowexpansion-demo">
                                                <div className="card">
                                                    <DataTable
                                                        paginator
                                                        scrollable scrollHeight="calc(100vh - 300px)"
                                                        rows={5}
                                                        value={telefones}
                                                    >
                                                        <Column field="ddd" header="DDD" style={{ width: 70 }} body={showDDDNumber} />
                                                        <Column field="numerotel" header="Número" body={showNumberPhone} />
                                                        <Column field="tipo_telefone" header="Tipo" style={{ width: 80 }} />
                                                        <Column field="prioritario" header="Prioritario" body={actionPrioritario} />
                                                        <Column
                                                            field="action"
                                                            header="Ações"
                                                            body={actionContatoBodyTemplate}
                                                            style={{ width: '80px' }} />
                                                    </DataTable>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Modal.Footer>
                                        <Button onClick={handleCloseModal}>Close</Button>
                                    </Modal.Footer>
                                </>
                            )}
                        </Modal.Footer>
                    </Modal.Body>
                </Container>
            </Modal>
        </>
    )
}