import React from 'react';
import Modal from "react-bootstrap/Modal";
import { InputText } from 'primereact/inputtext';
import { AbaAtendimento } from './DadosAbas/Atendimento';
import { Dialog } from 'primereact/dialog';

import { Container } from './styles';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { AbaVeiculo } from './DadosAbas/Veiculo';
import { AbaProprietario } from './DadosAbas/Proprietario';
import { AbaMensagens } from './DadosAbas/Mensagens';
// import { AbaAgenda } from './DadosAbas/Agenda';
// import { AbaAgendaUsuario } from './DadosAbas/AgendaUsuario';
import { AbaLogs } from './DadosAbas/Logs';
import { AbaDocumento } from './DadosAbas/Documento';
//import { AbaPendencia } from './DadosAbas/Pendencia';
import { AbaContatos } from './DadosAbas/Contatos';


export function ModalAtendimento({ ...props }) {
    return (
        <Dialog {...props} style={{ width: '60vw', height:'75vh' }}>
            <Container>
                <Form className="mt-3">
                    <div className="row row-cols-3 mb-4">
                        <div className="col">
                            <label>Placa</label><br />
                            <InputText value={'Placa Aqui'} disabled />
                        </div>
                        <div className="col">
                            <label>Modelo</label><br />
                            <InputText value={'Modelo Aqui'} disabled />
                        </div>
                        <div className="col">
                            <label>Ano</label><br />
                            <InputText value={'Ano Aqui'} disabled />
                        </div>
                    </div>
                    <Tabs className="mt-2 mb-2" defaultActiveKey="atendimento" transition={false} id="noanim-tab-example">
                        <Tab eventKey="atendimento" title="Atendimento">
                            <AbaAtendimento />
                        </Tab>
                        <Tab eventKey="veiculo" title="Veículo">
                            <AbaVeiculo />
                        </Tab>
                        <Tab eventKey="proprietario" title="Proprietário">
                            <AbaProprietario />
                        </Tab>
                        <Tab eventKey="mensagens" title="Mensagens">
                            <AbaMensagens />
                        </Tab>
                        <Tab eventKey="contatos" title="Contatos">
                            <AbaContatos />
                        </Tab>
                        {/* <Tab eventKey="agenda" title="Agenda">
                            <AbaAgenda />
                        </Tab> */}
                        <Tab eventKey="agendaUsuario" title="Agenda Usuário">
                            {/* <AbaAgendaUsuario /> */}
                        </Tab>
                        <Tab eventKey="logs" title="Logs">
                            <AbaLogs />
                        </Tab>
                        <Tab eventKey="documento" title="Documento">
                            <AbaDocumento />
                        </Tab>
                        {/* <Tab eventKey="pendencia" title="Pendência">
                            <AbaPendencia />
                        </Tab> */}
                    </Tabs>
                </Form>
            </Container>
        </Dialog>
    )
}