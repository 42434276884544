import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';

export function AbaDocumento() {
    const chooseOptions = { label: 'Adicionar', icon: 'pi pi-fw pi-plus', className: 'p-button-success' };
    const uploadOptions = { label: 'Upload', icon: 'pi pi-upload', className: 'p-button-success' };
    const cancelOptions = { label: 'Cancelar', icon: 'pi pi-times' };
    const [onUpload] = useState(null);

    return (
        <div>
            <div className="card">
                <DataTable className="p-datatable-responsive-demo" value={[{ operador: "Outro Fulano", documento: "Um Doocumento", data: "dd/mm/aaaa" }]}>
                    <Column field="data" header="Data" sortable />
                    <Column field="operador" header="Operador" sortable />
                    <Column field="documento" header="Documento" sortable />
                </DataTable>
            </div>
            <div className="p-col-12 mt-5">

                <FileUpload 
                    name="upload[]"
                    url=""
                    onUpload={onUpload}
                    multiple
                    maxFileSize={1000000}
                    emptyTemplate={<p className="p-m-0">Arraste e solte arquivos aqui para realizar o upload.</p>}
                    chooseOptions={chooseOptions}
                    uploadOptions={uploadOptions}
                    cancelOptions={cancelOptions}
                />
            </div>
        </div>
    )
}