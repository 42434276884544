import React, { useRef } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import Modal from "react-bootstrap/Modal";
import InputMask from '../../../../components/InputMask';

import { Container } from './styles';
import SelectUnform from '../../../../components/SelectUnform';

export function UpdateTelefone({ rowEditTel, handleUpdateTelefone, ...props }) {
    const toast = useRef(null);
    const formRef = useRef(null);

    const optionsTipo = [
        { value: "movel", label: "Movel" },
        { value: "fixo", label: "Fixo" }
    ]

    async function handleEditTelefone(data) {
        const validDDD = data.ddd;
        const validNumero = data.numerotel;
        const newDDD = validDDD.replace(/\.|-|\/|\\|\(|\)/g, '');
        const newNumero = validNumero.replace(/\.|-|\/|\\|\(|\)/g, '');

        const newData = {
            ...data,
            ddd: newDDD,
            numerotel: newNumero
        }

        await handleUpdateTelefone(newData)
        props.onHide()
    }


    return (
        <>
            <Toast ref={toast} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Container>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h2 style={{ textAlign: 'center' }} className="mb-2">Editar Telefone</h2>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormBootstrap
                            as={Form}
                            ref={formRef}
                            onSubmit={handleEditTelefone}
                            initialData={rowEditTel}
                        >
                            <Row>
                                <Col>
                                    <div
                                        style={{ display: "flex" }}
                                        className="schedule-item mb-2"
                                    >
                                        <FormBootstrap.Control
                                            style={{ maxWidth: 60 }}
                                            as={InputMask}
                                            mask="(99)"
                                            maskChar=""
                                            className="mr-2"
                                            placeholder="DDD"
                                            name="ddd"
                                            label="Das"
                                        />
                                        <FormBootstrap.Control
                                            as={InputMask}
                                            mask="99999-9999"
                                            maskChar=""
                                            placeholder="Telefone"
                                            name="numerotel"
                                            label="Até"
                                        />

                                        <SelectUnform
                                            className="ml-2"
                                            name="tipo_telefone"
                                            options={optionsTipo}
                                        >
                                            {optionsTipo.map(tipo => (
                                                <option key={tipo.value}>{tipo.label}</option>
                                            ))}
                                        </SelectUnform>
                                    </div>
                                </Col>
                            </Row>
                            <Button type="submit" className="mt-2">Atualizar</Button>
                        </FormBootstrap>
                    </Modal.Body>
                </Container>
            </Modal>
        </>
    )
}