import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    padding: 32px;

    button.close {
        color: red;
    }

    h2 {
        font-family: Roboto;
        font-weight: 500;
        font-size: 1.8rem;
    }

    .button {
        display: flex;
        justify-content: center;

        button {
            height: 40px;
            border: none;
            width: 100%;

            font-family: Roboto;

            background: #99d98c;

            transition: filter 0.2s;

            &:hover {
                filter: brightness(0.9);
            }
        }
    }

    svg {

        transition: filter 0.2s;

        &:hover {
            cursor: pointer;
            filter: brightness(0.5);
        }
    }
`;

export const RadioButton = styled.h5`
    background: ${(props) => props.isActive};
    color: ${(props) => props.isActive ? '#fff' : '#000'};
    /* border: ${(props) => props.isActiveBorder ? '10px solid #000' : '10px solid red'}; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    height: 32px;
    width: 120px;

    border: none;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
`;