import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    

    .logo {
        text-align: center;
        margin: 10px;

        img {
            width: 150px;
        }
    }
    .content {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        

        form {
            border: 1px solid #ccc;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
            padding: 32px;

            h2 {
                font-family: Roboto;
                font-weight: 400;
                font-size: 1.8rem;
                text-align: center;
            }
        }

        .groupLabel {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
`;