import React, { useCallback, useRef } from 'react';
import { Button, Col, Form as FormBootstrap, Row } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import { Form } from '@unform/web';
import Modal from "react-bootstrap/Modal";
import Input from '../../../components/Input';
import DatePicker from '../../../components/DatePicker';
// import SelectUnform from '../../../components/SelectUnform';

import * as Yup from "yup";

import { Container } from './styles';
import getValidationErrors from '../../../utils/getValidationErrors';

export function AddLaudo({ handleAddLaudo, idVeiculo, ...props }) {
    const toast = useRef(null);
    const formRef = useRef(null);

    const handleSubmit = useCallback(async (data) => {
        try {
            // let timestamp_data_emissaocsv = data.data_emissaocsv
            // let timestamp_data_relacionamento = data.data_relacionamento
            // let timestamp_data_vencimentocsv = data.data_vencimentocsv
            // if (data.datanascimento !== null) {
            //     timestamp_data_emissaocsv.toISOString()
            //     timestamp_data_relacionamento.toISOString()
            //     timestamp_data_vencimentocsv.toISOString()
            // }

            // const newDataLaudo = {
            //     ...data,
            //     data_emissaocsv,
            //     data_relacionamento,
            //     data_vencimentocsv
            // }
            const schema = Yup.object().shape({
                numerocsv: Yup.string().required("Obrigátorio"),
            });

            formRef.current?.setErrors({});

            await schema.validate(data, {
                abortEarly: false
            });

            await handleAddLaudo(data, idVeiculo);
            props.onHide()
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                const errors = getValidationErrors(err);

                formRef.current?.setErrors(errors);

                return;
            }
            toast.current.show({ severity: 'error', summary: 'Mensagem de Erro', detail: 'Falha ao cadastrar, tente novamente!' });
        }
    }, [handleAddLaudo, idVeiculo, props]);

    // const escopos = [
    //     { id: 1, label: 'escopo1' }
    // ]
    // const status = [
    //     { id: 1, label: 'status 1' },
    //     { id: 2, label: 'status 2' },
    // ]

    return (
        <>
            <Toast ref={toast} />
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                backdrop="static"
                centered
            >
                <Container>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h2 style={{ textAlign: 'center' }}>Adicionar Laudo</h2>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <FormBootstrap as={Form} ref={formRef} onSubmit={handleSubmit}>
                            <Row className="mb-2">
                                {/* <Col style={{ maxWidth: 400 }}>
                                    <FormBootstrap.Label>Escopo</FormBootstrap.Label>
                                    <SelectUnform name="escopo_id" as="select" defaultValue="escolha..">
                                        {escopos.map((escopo) => (
                                            <option key={escopo.id} value={escopo.id}>{escopo.label}</option>
                                        ))}
                                    </SelectUnform>
                                </Col> */}
                                <Col>
                                    <FormBootstrap.Label>Escopo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="escopo_id"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col >
                                    <FormBootstrap.Label className="mr-2">Data Relacionamento</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={DatePicker}
                                        name="data_relacionamento"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col >
                                    <FormBootstrap.Label className="mr-2">Data Emissao</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={DatePicker}
                                        name="data_emissaocsv"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <FormBootstrap.Label className="mr-2">Data Vencimento</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={DatePicker}
                                        name="data_vencimentocsv"
                                        type="date"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormBootstrap.Label>Local de Inspeção</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="local_inspecao"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col>
                                    <FormBootstrap.Label>Número Laudo</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        type="number"
                                        name="numerocsv"
                                    />
                                </Col>
                                <Col style={{ width: 500 }}>
                                    <FormBootstrap.Label>Numero de Inspeção de Tanque</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        type="number"
                                        name="numinspecaotanque"
                                        style={{ width: 200 }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col style={{ maxWidth: 400 }}>
                                    <FormBootstrap.Label>Status</FormBootstrap.Label>
                                    <SelectUnform name="status_id" as="select" defaultValue="escolha..">
                                        {status.map((stat) => (
                                            <option key={stat.id} value={stat.id}>{stat.label}</option>
                                        ))}
                                    </SelectUnform>
                                </Col> */}
                                <Col>
                                    <FormBootstrap.Label>Status</FormBootstrap.Label>
                                    <FormBootstrap.Control
                                        as={Input}
                                        name="status_id"
                                    />
                                </Col>
                            </Row>
                            <Button type="submit" className="mt-2">Adicionar</Button>
                        </FormBootstrap>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => props.onHide()}>Fechar</Button>
                    </Modal.Footer>
                </Container>
            </Modal>
        </>
    )
}