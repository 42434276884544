import styled from 'styled-components';

export const Container = styled.div`
    .p-datatable .p-datatable-tbody > tr {
        &:hover {
            filter: brightness(0.9);
            cursor: pointer;
        }
    }
`;

export const Content = styled.div`
    margin: 32px 32px;

    section + section {
        margin-top: 20px;
    }

    .dataTable1 {

        .searchButtons {
            margin-bottom: 10px;

            button {
                transition: filter 0.2s;
            }
            
            button:hover {
                filter: brightness(0.9);
            }

            button + button {
                margin-left: 5px;
            }
        }
    }

    .icon1 {
            position: relative;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            /* background: #000; */

            svg {
                position: absolute;
                right: 10px;
                top: 15px;
                z-index: 10;
                transition: filter 0.2s;

                &:hover {
                    cursor: pointer;
                    filter: brightness(0.6);
                }
            }
        }

        .5pxespaco{
            margin-left: 5px;
            margin-right: 5px;
        }
`;